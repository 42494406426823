import { useNavigate } from "react-router-dom";
import "./index.css";
import { Form, Input } from "antd";
import { getAccountUserDetails, postUserNameAndPassword } from "../Service/service";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import logo_lg from "../../assets/images/leftNavigate/logo_lg.svg"
import { useDispatch } from "react-redux";
import { updateJwtToken } from "../../globalDataManager/UserDetails/userDetails";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  console.log(username);

  dispatch(updateJwtToken());
  const getData = async () => {
    const userAccountRes = await getAccountUserDetails(navigate);
    if (userAccountRes !== "invalid" && userAccountRes) {
      // setEmployeeId(userAccountRes.employeeId);  
      console.log(userAccountRes, "top menu response");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // // Check if username and password are provided
    // if (!username || !password) {

    //     return;
    // }

    const bodyContent = {
      userName: username,
      password: password,
    };

    try {
      if (username && password) {
        const data = await postUserNameAndPassword(bodyContent);
        console.log(data);
        setMessage(data.message);
        if (data.statusMessage === "Successfull") {
          localStorage.setItem(
            "Friska_Database",
            JSON.stringify(data.result.token)
          );
          onFinish(data);
          correctUsernameAndPassword();
        } else {
          // Handle incorrect username or password
          setMessage("Incorrect username or password");
          incorrectUsernameAndPassword();
        }
      } else {
        setMessage("Username and password are required");
        noUsernameAndPassword();
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  const onFinish = async(values) => {
    // Assuming 'statusMessage' is part of values object
    if (values.statusMessage === "Successfull") {
      // Navigate to a specific route upon success
      dispatch(updateJwtToken());
   
      const userAccountRes = await getAccountUserDetails(navigate);
      console.log(userAccountRes, "userAccountRes")

     
      if (userAccountRes !== "invalid" && userAccountRes) {
        // setEmployeeId(userAccountRes.employeeId);  
        console.log(userAccountRes, "top menu response");
        if(userAccountRes.status == 0){
          console.log("navigate yo dash")
          navigate("/dashboard");
         } else if (userAccountRes.status == 1) {
          console.log("navigate yo role-type")
          navigate("/select-role-type"); 
          
         } else if (userAccountRes.status == 2) {
          console.log("navigate yo create-org")
          navigate("/create-organization"); 
         
         } else if (userAccountRes.status == 4) {
          console.log("navigate yo reg")
          navigate("/organization-registration");
         }


    };
    

   
      
      
    } else {
      // Display error message or handle other cases
      message.error("Invalid status message");
    }
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const noUsernameAndPassword = () =>
    toast.error("Username and password are required");
  const incorrectUsernameAndPassword = () =>
    toast.error("Incorrect Username and password");
  const correctUsernameAndPassword = () =>
    toast.success("Successfully Logged IN");
  return (
    <div className="login_container">
      <div className="left_container">
        
      </div>
      <div className="right_container">
        <div className="left_card">
           <img src={logo_lg} className="pb-3" alt="Logo" />

          <h1 className="login_title">Welcome back</h1>
          <p className="login_content">Login to your Friska AI account</p>
          <ToastContainer />
          <Form
          className="mb-3"
            name="basic"
            initialValues={{
              remember: true,
            }}
            // onFinish={() => onFinish(statusMessage)}
            onFinishFailed={onFinishFailed}
            autoComplete="on"
          >
            <Form.Item
              name="username"
             
              rules={[
                {
                  required: true,
                  message: "Please enter your username!",
                },
              ]}
            >
              <Input
                placeholder="Username"
                className="loginMb_16"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item> 

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <p className='btn-none forgot_password'  onClick={() => navigate("/forgot-password")} >Forgot password?</p>
            {/* {message && <p className='errorMsg'>{message}</p>} */}

            <Form.Item>
              <button
                className="blue_btn mt-3"
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
