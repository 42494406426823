import "./index.css";
import logo from "../../../assets/images/leftNavigate/logo.svg";
import schoolsIcon from "../../../assets/images/leftNavigate/schoolsIcon.svg";
import instructorIcon from "../../../assets/images/leftNavigate/instructorIcon.svg";
import myLibraryIcon from "../../../assets/images/leftNavigate/myLibraryIcon.svg";
import reportIcon from "../../../assets/images/leftNavigate/reportIcon.svg";
import supportIcon from "../../../assets/images/leftNavigate/supportIcon.svg";
import KalariGroupIcon from "../../../assets/images/leftNavigate/KalariGroupIcon.svg";
import collapseBtn from "../../../assets/images/leftNavigate/collapseBtn.svg";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Input } from "antd";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Password } from 'primereact/password';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const Settings = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <p onClick={() => navigate("/settings")} className="menu_dropdown_item">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1811_85805)">
              <path
                d="M8.98438 10C9.51481 10 10.0235 9.78929 10.3986 9.41421C10.7737 9.03914 10.9844 8.53043 10.9844 8C10.9844 7.46957 10.7737 6.96086 10.3986 6.58579C10.0235 6.21071 9.51481 6 8.98438 6C8.45394 6 7.94523 6.21071 7.57016 6.58579C7.19509 6.96086 6.98438 7.46957 6.98438 8C6.98438 8.53043 7.19509 9.03914 7.57016 9.41421C7.94523 9.78929 8.45394 10 8.98438 10Z"
                stroke="#334155"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.31738 8.5863V7.41297C2.31738 6.71963 2.88405 6.1463 3.58405 6.1463C4.79072 6.1463 5.28405 5.29297 4.67738 4.2463C4.33072 3.6463 4.53738 2.8663 5.14405 2.51963L6.29738 1.85963C6.82405 1.5463 7.50405 1.73297 7.81738 2.25963L7.89072 2.3863C8.49072 3.43297 9.47738 3.43297 10.084 2.3863L10.1574 2.25963C10.4707 1.73297 11.1507 1.5463 11.6774 1.85963L12.8307 2.51963C13.4374 2.8663 13.644 3.6463 13.2974 4.2463C12.6907 5.29297 13.1841 6.1463 14.3907 6.1463C15.0841 6.1463 15.6574 6.71297 15.6574 7.41297V8.5863C15.6574 9.27963 15.0907 9.85297 14.3907 9.85297C13.1841 9.85297 12.6907 10.7063 13.2974 11.753C13.644 12.3596 13.4374 13.133 12.8307 13.4796L11.6774 14.1396C11.1507 14.453 10.4707 14.2663 10.1574 13.7396L10.084 13.613C9.48405 12.5663 8.49738 12.5663 7.89072 13.613L7.81738 13.7396C7.50405 14.2663 6.82405 14.453 6.29738 14.1396L5.14405 13.4796C4.85345 13.3123 4.6411 13.0365 4.55361 12.7128C4.46612 12.3891 4.51064 12.0439 4.67738 11.753C5.28405 10.7063 4.79072 9.85297 3.58405 9.85297C2.88405 9.85297 2.31738 9.27963 2.31738 8.5863Z"
                stroke="#334155"
                strokeWidth="1.2"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1811_85805">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.984375)"
                />
              </clipPath>
            </defs>
          </svg>
          Settings
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="menu_dropdown_item_logout" onClick={() => navigate("/")}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.77137 9.74634L10.478 8.03967L8.77137 6.33301M3.65137 8.03967H10.4314"
              stroke="#E81C28"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.98438 2.66699C11.931 2.66699 14.3177 4.66699 14.3177 8.00033C14.3177 11.3337 11.931 13.3337 8.98438 13.3337"
              stroke="#E81C28"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Log out
        </p>
      ),
    },
  ];
  const [editDetails, seteditDetails] = useState(null);

  const handleClickEditDetails = (event) => {
    seteditDetails(event.currentTarget);
  };

  const handleCloseEditDetails = () => {
    seteditDetails(null);
  };

  const openEditDetails = Boolean(editDetails);
  const id = openEditDetails ? "simple-popover" : undefined;


  const [changePassword, setchangePassword] = useState(null);

  const handleClickchangePassword = (event) => {
    setchangePassword(event.currentTarget);
  };

  const handleClosechangePassword = () => {
    setchangePassword(null);
  };


  const openchangePassword = Boolean(changePassword);
  const idd = openchangePassword ? "simple-popover" : undefined;



  const [editLinks, seteditLinks] = useState(null);

  const handleClickeditLinks = (event) => {
    seteditLinks(event.currentTarget);
  };

  const handleCloseeditLinks = () => {
    seteditLinks(null);
  };

  const openeditLinks = Boolean(editLinks);
  const iddd = openeditLinks ? "simple-popover" : undefined;
  
  const [value, setValue] = useState('');

  return (
    <div className="d-flex">
      <div className="settingsLeftNavigate_container">
        <div className="d-flex flex-column">
          <button className="btn-none settings_back_btn" onClick={() => navigate("/community")} type="button">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="19"
              viewBox="0 0 12 19"
              fill="none"
            >
              <path
                d="M11.9844 1.69848L10.3066 0L0.984375 9.5L10.316 19L11.9844 17.3015L4.32114 9.5L11.9844 1.69848Z"
                fill="#0D4B6F"
              />
            </svg>
          </button>
        </div>
        <div className="leftNav_bottom">
          <Dropdown
            menu={{
              items,
            }}
            placement="topLeft"
          >
            <div className="d-flex align-items-center gap-2">
              <img src={KalariGroupIcon} alt="KalariGroupIcon" />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="settingsRightNavigate_container">
        <div className="settingsTitleSticky">
          <h1 className="pageTitle">Settings</h1>
          <p className="pageSub">Add and edit details in this section</p>
        </div>
        <div className="d-flex align-items-center flex-column gap-4">
          <div className="settingsCard">
            <div className="d-flex align-items-center gap-3 mb-2">
              <h2 className="settingsProfileIcon">KH</h2>
              <h3 className="pageTitle">Kalari Harmony</h3>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Email</p>
              <p className="detailsSub">admin@kalariharmony.com</p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Phone No</p>
              <p className="detailsSub">989-418-8298</p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Address</p>
              <p className="detailsSub">209 Dickenson St.Romeo, MI 48065</p>
            </div>
            <button
              className="outline_btn"
              aria-describedby={id}
              variant="contained"
              onClick={handleClickEditDetails}
              type="button"
            >
              Edit details
            </button>
            <Popover
              id={id}
              open={openEditDetails}
              anchorEl={editDetails}
              onClose={handleCloseEditDetails}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="d-flex justify-content-between align-items center ">
                <h1 className="editDetails_title">Profile details</h1>
                <button
                  className="btn-none"
                  onClick={handleCloseEditDetails}
                  type="button"
                >
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3346 21.6315L22.6346 10.3314M22.6415 21.6246L11.3278 10.3383"
                      stroke="#64748B"
                      strokeWidth="1.49909"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex flex-column row-gap-3 mt-3">
                <div className="col-md-12">
                  <label className="modal_label">Display name</label>
                  <Input className="border-input" value="Kalari Harmony" />
                </div>

                <div className="col-md-12">
                  <label className="modal_label">Email</label>
                  <Input
                    className="border-input"
                    value="admin@kalariharmony.com"
                  />
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Phone no</label>
                  <Input className="border-input" value="+1 989-418-8298" />
                </div>
              </div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  className="outline_btn"
                  type="button"
                  onClick={handleCloseEditDetails}
                >
                  Update
                </button>
              </div>
            </Popover>
          </div>
          <div className="settingsCard">
            <div className="d-flex align-items-center gap-3 mb-2">
              <h2 className="settingsCardTitle">Security</h2>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Password</p>
              <p className="detailsSub">************</p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Last updated: 01/18/2023 09:00 AM</p>
            </div>
            <button
              className="outline_btn"
              aria-describedby={id}
              variant="contained"
              onClick={handleClickchangePassword}
              type="button"
            >
              Change password
            </button>
            <Popover
              idd={idd}
              open={openchangePassword}
              anchorEl={changePassword}
              onClose={handleClosechangePassword}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="d-flex justify-content-between align-items center ">
                <h1 className="editDetails_title">Change password</h1>
                <button
                  className="btn-none"
                  onClick={handleClosechangePassword}
                  type="button"
                >
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3346 21.6315L22.6346 10.3314M22.6415 21.6246L11.3278 10.3383"
                      stroke="#64748B"
                      strokeWidth="1.49909"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex flex-column row-gap-3 mt-3">
                <div className="col-md-12">
                  <label className="modal_label">Current password</label>
                  <Input.Password
                  className="border-input"
                    editDetails
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                /> 
                </div>
                <div className="col-md-12">
                  <label className="modal_label">New password</label>
                  <Input.Password
                  className="border-input"
                    editDetails
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                /> 
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Confirm new password</label>
                  <Input.Password
                  className="border-input"
                    editDetails
                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                /> 
                </div>

                 
              </div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  className="outline_btn"
                  type="button"
                  onClick={handleClosechangePassword}
                >
                  Update
                </button>
              </div>
            </Popover>
          </div>
          <div className="settingsCard">
            <div className="d-flex align-items-center gap-3 mb-2">
              <h2 className="settingsCardTitle">Social links</h2>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Linked In</p>
              <p className="detailsSub">
                https://www.linkedin.com/adminkalari12
              </p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Instagram</p>
              <p className="detailsSub">
                https://www.instagram.com/adminkalari12
              </p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Youtube</p>
              <p className="detailsSub">
                https://www.youtube.com/adminkalari12
              </p>
            </div>
            <div className="d-flex">
              <p className="detailsTitle">Facebook</p>
              <p className="detailsSub">
                https://www.facebook.com/adminkalari12
              </p>
            </div>
            <button
              className="outline_btn"
              aria-describedby={id}
              variant="contained"
              onClick={handleClickeditLinks}
              type="button"
            >
              Edit Links
            </button>
            <Popover
              iddd={iddd}
              open={openeditLinks}
              anchorEl={editLinks}
              onClose={handleCloseeditLinks}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="d-flex justify-content-between align-items center ">
                <h1 className="editDetails_title">Social links</h1>
                <button
                  className="btn-none"
                  onClick={handleCloseeditLinks}
                  type="button"
                >
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3346 21.6315L22.6346 10.3314M22.6415 21.6246L11.3278 10.3383"
                      stroke="#64748B"
                      strokeWidth="1.49909"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="d-flex flex-column row-gap-3 mt-3">
                <div className="col-md-12">
                  <label className="modal_label">Linked in</label>
                  <Input className="border-input" />
                </div>

                <div className="col-md-12">
                  <label className="modal_label">Instagram</label>
                  <Input
                    className="border-input" 
                  />
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Youtube</label>
                  <Input className="border-input"  />
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Facebook</label>
                  <Input className="border-input"  />
                </div>
              </div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  className="outline_btn"
                  type="button"
                  onClick={handleCloseeditLinks}
                >
                  Update
                </button>
              </div>
            </Popover>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
