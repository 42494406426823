import { useState } from "react";
import "./index.css"
import { Form, Input } from "antd";
import logo from "../../../assets/images/createOrganization/logo.svg"
import { useNavigate } from "react-router-dom";
import { getAccountUserDetails, getChangePasswordForUserService } from "../../Service/service";

const CreateOrganization = () => {

    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState('');
    const [userName, setUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [message, setMessage] = useState('');
  const [changePasswordDisabled, setChangePasswordDisabled] = useState(true);
  

  
     
      
      const checkPasswordStrength = (password) => {
        let strength = '';
        if (password.length < 6) {
          strength = 'Too short';
        } else if (password.length >= 6 && password.length < 8) {
          strength = 'Weak';
        } else if (password.length >= 8 && password.match(/[A-Z]/) && password.match(/[0-9]/) && password.match(/[^A-Za-z0-9]/)) {
          strength = 'Strong';
        } else if (password.length >= 8) {
          strength = 'Moderate';
        }
        setPasswordStrength(strength);
      };
    
      const handleNewPasswordChange = (event) => {
        const newPass = event.target.value;
        setNewPassword(newPass);
        validatePasswords(newPass, confirmPassword);
        checkPasswordStrength(newPass)
    };
    
    const handleConfirmPasswordChange = (event) => {
        const confirmPass = event.target.value;
        setConfirmPassword(confirmPass);
        validatePasswords(newPassword, confirmPass);
    };
     
    
      const validatePasswords = (newPass, confirmPass) => {
        if (newPass !== confirmPass) {
            setError('Passwords do not match!');
        } else {
            setError('');
            setChangePasswordDisabled(false)
            
        }
    };


    const handleChangePasswordForUser = async () => {
      try {
        const confirmedPassword = newPassword
        const confirmedPasswordResponse = await getChangePasswordForUserService(confirmedPassword, navigate);
        const userAccountRes = await getAccountUserDetails(navigate);
        console.log(userAccountRes, "userAccountRes")
  
       
        if (userAccountRes !== "invalid" && userAccountRes) {
          // setEmployeeId(userAccountRes.employeeId);  
          console.log(userAccountRes, "top menu response");
          if(userAccountRes.status == 0){
            console.log("navigate yo dash")
            navigate("/dashboard");
           } else if (userAccountRes.status == 1) {
            console.log("navigate yo role-type")
            navigate("/select-role-type"); 
            
           } else if (userAccountRes.status == 2) {
            console.log(userAccountRes.status, "navigate yo create-org")
            navigate("/create-organization"); 
           
           } else if (userAccountRes.status == 4) {
            console.log("navigate yo reg")
            navigate("/organization-registration");
           }
          } 
         
      } catch (e) {
        console.log(e.message);
      }
    };

    return (
        <div className="create_organization_container">
            <div className="logo_container">
                <img src={logo} alt="logo" />
            </div>
           <div className="d-flex justify-content-center w-100">
           <div className="center_organization_container">
                <h2 className="oragnization_title">Create password</h2>
                <p className="oragnization_sub">Create New Password for your Portal</p>
                <div className="row">
                 
      <div className="col-md-12 mb-3">
        <label className="modal_label">
        New Password <span className="text-danger">*</span>
        </label>
        <Form.Item
          name="password"
          style={{marginBottom: "16px"}}
         
          rules={[
            {
              required: true,
              message: "Enter your new password",
            },
          ]}
        >
          <Input.Password placeholder="New Password" value={newPassword}
          onChange={handleNewPasswordChange} />
          {newPassword && (
          <p className={`password-strength ${passwordStrength.toLowerCase()}`}>
          {passwordStrength}
          </p>
        )}
        </Form.Item>
      </div>
      <div className="col-md-12 mb-3">
        <label className="modal_label">
        Confirm New Password <span className="text-danger">*</span>
        </label>
        <Form.Item
          name="password" 
          
          rules={[
            {
              required: true,
              message: "Confirm your new password",
            },
          ]}
        >
          <Input.Password placeholder="Confirm Password"  value={confirmPassword}
          onChange={handleConfirmPasswordChange}/>
          <p className="password-match-error" style={{ color: 'red' }}>{error}</p>
        </Form.Item>
      </div>

      
        
      <button className="organization_btn ms-2" type="button" disabled={changePasswordDisabled}  style={{width: "200px"}} onClick={handleChangePasswordForUser}>Continue</button>
                </div>
            </div>
           </div>
        </div>
    )
}

export default CreateOrganization;