import { Routes, Route, useLocation, useNavigate } from "react-router-dom"; 
import './App.css';
import Login from './component/Login'; 
import Community from "./component/communityModule/Community";
import CommunitySchoolList from "./component/communityModule/CommunitySchoolList";
import ViewSchoolProfile from "./component/communityModule/ViewSchoolProfile";
import Instructor from "./component/instructorModule/Instructor";
import InstructorList from "./component/instructorModule/InstructorList";
import Reports from "./component/reportsModule/Reports";
import MyLibrary from "./component/myLibraryModule/MyLibrary"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";   
import "primereact/resources/primereact.min.css";                   
import "primeicons/primeicons.css";               
import Settings from "./component/settingsModule/Settings";
import Dashboard from "./component/dashboardModule/Dashboard";
import MedicalConditionList from "./component/dashboardModule/MedicalConditionList";
import CreateSchool from "./component/CreateSchool";
import ForgotPassword from "./component/ForgotPassword";
import Schools from "./component/Schools";
import RightTopHeaderMenu from "./component/shareableResources/RightTopHeaderMenu";
import MyPatient from "./component/myPatientModule/MyPatient";
import PatientChart from "./component/myPatientModule/PatientChart";
import WellnessProgram from "./component/wellnessProgramModule/WellnessProgram";
import MyAccount from "./component/myAccountModule/MyAccount";
import "react-toastify/dist/ReactToastify.css";
import { updateJwtToken } from "./globalDataManager/UserDetails/userDetails";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { ContentProtectedRoute, OnboardingProtectedRoute } from "./component/ProtectedRoute/protectedRoute";
import CreateOrganization from "./component/createOrganization/CreateOrganization";
import OrganizationAccountCreated from "./component/createOrganization/OrganizationAccountCreated";
import OrganizationRegistration from "./component/createOrganization/OrganizationRegistration";
import WelcomeFriska from "./component/createOrganization/WelcomeFriska";
import Users from "./component/userModule/Users"; 
import SelectRoleType from "./component/SelectRoleType";
import UserProfile from "./component/userModule/UsersProfile";


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(location);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/sign-in");
    }

    dispatch(updateJwtToken());
  }, [location.pathname]);

  return (
    <Routes> 
       <Route element={<OnboardingProtectedRoute />}>
        <Route exact path="/sign-in" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/create-organization" element={<CreateOrganization />} />  
    <Route exact path="/organization-created" element={<OrganizationAccountCreated />} />  
    <Route exact path="/organization-registration" element={<OrganizationRegistration />} />  
    <Route exact path="/welcome-friska" element={<WelcomeFriska />} />  
  
    <Route exact path="/select-role-type" element={<SelectRoleType />} />  
      </Route>
 
      <Route element={<ContentProtectedRoute />}>
      <Route exact path="/create-school" element={<CreateSchool />} /> 
    
    <Route exact path="/dashboard" element={<Dashboard />} />
    {/* <Route exact path="/medical-condition-list" element={<MedicalConditionList />} /> */}
    <Route exact path="/community" element={<Community />} />
    <Route exact path="/community-school-list" element={<CommunitySchoolList />} />
    <Route exact path="/view-school-profile" element={<ViewSchoolProfile />} />
    <Route exact path="/instructor" element={<Instructor />} />
    <Route exact path="/instructor-list" element={<InstructorList />} />
    <Route exact path="/my-library" element={<MyLibrary />} />
    <Route exact path="/reports" element={<Reports />} /> 
    <Route exact path="/settings" element={<Settings />} /> 
    <Route exact path="/schools" element={<Schools />} />  
    <Route exact path="/my-patient" element={<MyPatient />} />  
    <Route exact path="/patient-chart" element={<PatientChart />} />  
    <Route exact path="/wellness-program" element={<WellnessProgram />} />  
    <Route exact path="/my-account" element={<MyAccount />} />  
    <Route exact path="/users" element={<Users />} />  
    <Route exact path="/user-profile" element={<UserProfile />} />  
   
    </Route>
  </Routes>
  );
}

export default App;
