import "./index.css"
import logo from "../../../assets/images/createOrganization/logo.svg"
import { useNavigate } from "react-router-dom";

const OrganizationAccountCreated = () => {

    const navigate = useNavigate();


    return (
        <div className="create_organization_container">
        <div className="logo_container">
            <img src={logo} alt="logo" />
        </div>
       <div className="d-flex justify-content-center w-100">
       <div className="center_organization_container">
            <h2 className="oragnization_created_title">Your Friska account has been created</h2>
            <p className="oragnization_created_sub">Ready to set up your Admin Portal and We'll walk you through each step.</p>
            <button className="organization_btn" type="button" onClick={() => navigate("/organization-registration")}>Continue to setup</button>
        </div>
       </div>
    </div>
    )
}

export default OrganizationAccountCreated;