import LeftNavigationBar from "../../shareableResources/LeftNavigationBar";
import "./index.css";
import plusIcon from "../../../assets/images/rightContainer/plusIcon.svg";
import communityIcon from "../../../assets/images/rightContainer/communityIcon.svg";
import closeBtnIcon from "../../../assets/images/rightContainer/closeBtnIcon.svg";
import noCommunityCardIcon from "../../../assets/images/rightContainer/noCommunityCardIcon.svg";
import StickyLabel from "../../shareableResources/StickyLabel";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import RightTopHeaderMenu from "../../shareableResources/RightTopHeaderMenu";
import { Input, message, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import Password from "antd/es/input/Password";
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import {
  getAllGenderService,
  getAllMedicalConditionService,
  getInvitationLink,
  getMyPatientsService,
  postAddCheifComplaintService,
  postAddPatientDetailsService,
  postAddPatientInsuranceDetailsService,
} from "../../Service/service";
import { updateJwtToken, updateSelectedMenu } from "../../../globalDataManager/UserDetails/userDetails";
import TextArea from "antd/es/input/TextArea";

const MyPatient = () => {
  const navigate = useNavigate(); 
 
  const dispatch = useDispatch();

  const [myPatientList, setMyPatientList] = useState([]);
  const [disableNextButton, setDisableNextButton] = useState(true); 
  const [disableBackButton, setDisableBackButton] = useState(true); 
  const [patientLastName, setPatientLastName] = useState("");
  const [genderList, setGenderList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1")
  const [searchPatientName, setSearchPatientName] = useState("")
  const [selectedMedicalCondition, setSelectedMedicalCondition] = useState("");
  const [medicalConditionList, setMedicalConditionList] = useState([]);
  const [notes, setNotes] = useState("");
  const [openEnroll, setopenEnroll] = useState(false);
  const [patientIdFromApi, setPatientIdFromApi] = useState({
    id: 0,
    status: ""
  })
  const [isBackButtonTriggered, setIsBackButtonTriggered] = useState(false)
  

 


  const [patientDetails, setPatientDetails] = useState({
    patientFirstName: "",
    patientMiddleName: "",
    patientLastName: "",
    patientDOB: "",
    patientId: "",
    selectedGender: "",
    email: "",
    mobile: "",
    sexualOrientation: "",
    maritalStatus: "",
    religion: "",
    race: "",
    ssn: "",
    hometelephone: "",
    worktelephone: "",
    othertelephone: "",
    // patientFullName: patientFullName,
    //dateOfBirth: dateOfBirth,
    PatientAddress: {
      address1: "",
      address2: "",
      county: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
    },
  })

  console.log(patientDetails, "setPatientDetails")
  const [patientInsuranceDetails, setPatientInsuranceDetails] = useState({
          patientId: "",
          insuranceType: "",
          insuranceCategory: "",
          insuranceCompany: "",
          relationshipToPatient: "",
          firstName: "",
          middleName: "",
          lastName: "",
          sex: "",
          subscribedInsured: "",
          copay: "",
          insuranceSsn: "",
          policyNo: "",
          groupPolicyNo: "",
          programName: "",
          billingMethod: "",
          effectiveDate: "",
          terminationDate: "",
          addressLine1: "",
          addressLine2: "",
          county: "",
          insuranceCity: "",
          insuranceState: "",
          additionalInfo: "",
   })
 
   const getFirstAndLastLetters = (name) => {
    const words = name.trim().split(' ');
    if (words.length === 0) return ''; // Handle case where name might be empty

    const firstLetter = words[0][0];
    const lastLetter = words[words.length - 1][0];
    // console.log(firstLetter , lastLetter)

    return `${firstLetter ?? ""}${lastLetter ?? ""}`;
  };
  useEffect(() => {
    dispatch(updateJwtToken()); 
    dispatch(updateSelectedMenu("mypatient")); 

    if (selectedTab === "1") {
      setDisableBackButton(true)
    } else {
      setDisableBackButton(false)
    }

    const fetchData = async () => {
      dispatch(updateJwtToken());
      try {
        const genderResponse = await getAllGenderService();
        if (genderResponse !== "invalid" && genderResponse) {
          setGenderList(genderResponse);
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchData();
  }, []);


  const checkForPatientCondition = () => {
    console.log("triggred")
    setDisableNextButton(true)
    if (patientDetails.patientFirstName && patientDetails.patientLastName && patientDetails.patientDOB && patientDetails.selectedGender  && patientDetails.mobile && patientDetails.email && patientDetails.PatientAddress.address1 ) {
      setDisableNextButton(false)
    } else {
      setDisableNextButton(true)
    }
  }

  // adding patient details
  useEffect(() => {
    checkForPatientCondition()
  }, [patientDetails])

// adding patient insurance details
  useEffect(() => {
    setDisableNextButton(true)
    // setIsBackButtonTriggered(true)
    if (patientInsuranceDetails.insuranceType && patientInsuranceDetails.insuranceCategory && patientInsuranceDetails.insuranceCompany && patientInsuranceDetails.relationshipToPatient && patientInsuranceDetails.firstName && patientInsuranceDetails.lastName &&  patientInsuranceDetails.addressLine1 ) {
      setDisableNextButton(false)
    } else {
      setDisableNextButton(true)
    }
  }, [patientInsuranceDetails])

// adding Chief Complaint
  useEffect(() => {
    if (selectedMedicalCondition) {
      setDisableNextButton(false)
    } else {
      setDisableNextButton(true)
    }
  }, [selectedMedicalCondition]) 

  const addedEnrollMessage = (e) => {
    fetchInvitationLink(e)
    chiefComplaint(e)
      setDisableNextButton(false) 
      postInsuranceDetails(e)
      e.preventDefault();
      handleCloseAddInstructor(true)

    
      
    messageApi
      .open({
        type: "loading",
        content: "Sending Invitation",
        duration: 1.5,
      })
      .then(() =>
        message.success("Invitation has been sent successfully", 1.5, setopenEnroll(false))
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const handleCloseEnroll = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenEnroll(false); 
    }
  }; 
  const handleChangesGender = (event) => {
    setPatientDetails(prevState => ({...prevState, selectedGender: event.target.value}))
    checkForPatientCondition() 
    
  };
  const handleChangesGenderInsurance = (event) => {
    
    setPatientInsuranceDetails(prevState => ({...prevState, selectedGender: event.target.value}))
    console.log(setPatientInsuranceDetails, "handleChangesGenderInsurance")
    
  };

  const postPatientDetails = async (e) => {
    e.preventDefault();
    const bodyContent = {
      recordedBy: "",
      patientFirstName: patientDetails.patientFirstName,
      patientMiddleName: patientDetails.patientMiddleName,
      patientLastName: patientDetails.patientLastName,
      patientDOB: new Date(patientDetails.patientDOB),
      gender: patientDetails.selectedGender,
      email: patientDetails.email,
      mobile: patientDetails.mobile,
      sexualOrientation: patientDetails.sexualOrientation,
      maritalStatus: patientDetails.maritalStatus,
      religion: "",
      race: patientDetails.race,
      ssn: patientDetails.ssn,

      hometelephone: patientDetails.hometelephone,
      worktelephone: patientDetails.worktelephone,
      othertelephone: patientDetails.othertelephone,
      // patientFullName: patientFullName,
      //dateOfBirth: dateOfBirth,

      PatientAddress: {

        address1: patientDetails.PatientAddress.address1,
        address2:  patientDetails.PatientAddress.address2,
        county:  patientDetails.PatientAddress.county,
        city:  patientDetails.PatientAddress.city,
        pincode: Number( patientDetails.PatientAddress.pincode),
        state:  patientDetails.PatientAddress.state,
        country:  patientDetails.PatientAddress.country,
      },
    };

   
    

    try {
     
        const patientDetailsResponse = await postAddPatientDetailsService(navigate, bodyContent);
       
      if (patientDetailsResponse !== "invalid" && patientDetailsResponse) {
        if(patientDetailsResponse.status === 0){
          setPatientIdFromApi((prevState) => ({...prevState, id: patientDetailsResponse.patientId ?? 0, status: patientDetailsResponse.status}))
  
   setPatientDetails((prevState) => ({...prevState, id: patientDetailsResponse.patientId ?? 0, status: patientDetailsResponse.status}))
        } else { 
          message.error(patientDetailsResponse.statusMessage);
        }
       
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const postInsuranceDetails = async (e) => {
    e.preventDefault();

    try { 
        const insuranceBodyContent = {
          insuranceID: 0,
          patientId: patientInsuranceDetails.patientIdFromApi,
          userId: patientInsuranceDetails.userId,
          recordedDate: new Date(), 
          insuranceType: patientInsuranceDetails.insuranceType,
          insuranceCategory: patientInsuranceDetails.insuranceCategory,
          insuranceCompany: patientInsuranceDetails.insuranceCompany,
          relationshipToPatient: patientInsuranceDetails.relationshipToPatient,
          firstName: patientInsuranceDetails.firstName,
          middleName: patientInsuranceDetails.middleName,
          lastName: patientInsuranceDetails.lastName,
          sex: patientInsuranceDetails.sex,
          subscribedInsured: patientInsuranceDetails.subscribedInsured,
          copay: patientInsuranceDetails.copay,
          ssn: patientInsuranceDetails.insuranceSsn,
          proposer: patientInsuranceDetails.proposer,
          policyNo: patientInsuranceDetails.policyNo,
          groupPolicyNo: patientInsuranceDetails.groupPolicyNo,
          cardNo: patientInsuranceDetails.cardNo,
          programName: patientInsuranceDetails.programName,
          billingMethod: patientInsuranceDetails.billingMethod,
          effectiveDate: new Date(patientInsuranceDetails.effectiveDate),
          terminationDate: new Date(patientInsuranceDetails.terminationDate),
          addressLine1: patientInsuranceDetails.addressLine1,
          addressLine2: patientInsuranceDetails.addressLine2,
          county: patientInsuranceDetails.county,
          city: patientInsuranceDetails.insuranceCity,
          state: patientInsuranceDetails.insuranceState,
          additionalInfo: patientInsuranceDetails.additionalInfo,
        };

        if (patientIdFromApi.status == "0") {

          addedInstructorMessage(e)
        const response = await postAddPatientInsuranceDetailsService(
          navigate, insuranceBodyContent 
        ); 
        await getMyPatientData(navigate)
        // addedInstructorMessage()
        setIsBackButtonTriggered(true)
        setPatientDetails((prevState) => ({
          patientFirstName: "",
          patientMiddleName: "",
          patientLastName: "",
          patientDOB: "",
          patientId: "",
          selectedGender: "",
          email: "",
          mobile: "",
          sexualOrientation: "",
          maritalStatus: "",
          religion: "",
          race: "",
          ssn: "",
          hometelephone: "",
          worktelephone: "",
          othertelephone: "",
          PatientAddress: {
            address1: "",
            address2: "",
            county: "",
            city: "",
            pincode: "",
            state: "",
            country: "",
          },
        }));

        setPatientInsuranceDetails((prevState) => ({
           patientId: "",
          insuranceType: "",
          insuranceCategory: "",
          insuranceCompany: "",
          relationshipToPatient: "",
          firstName: "",
          middleName: "",
          lastName: "",
          sex: "",
          subscribedInsured: "",
          copay: "",
          insuranceSsn: "",
          policyNo: "",
          groupPolicyNo: "",
          programName: "",
          billingMethod: "",
          effectiveDate: "",
          terminationDate: "",
          addressLine1: "",
          addressLine2: "",
          county: "",
          insuranceCity: "",
          insuranceState: "",
          additionalInfo: "",
        }));
       
          
         
        
      } else {
        failedAddingPatientMessage() 
        setopenAddCommunity(false)
      }
        
      
    } catch (error) {
      console.log(error.message);
    }
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSsnMessage, setErrorSsnMessage] = useState("");
  const [errorEmailValidateMessage, setErrorEmailValidateMessage] = useState("");
  const emailRegex = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  const handleMobileValidate = (e) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (sanitizedValue.length <= 10) {
      setPatientDetails((prevState) => ({ ...prevState, mobile: sanitizedValue }));
    }

    if (sanitizedValue.length !== 10) {
      setErrorMessage("Mobile number must be exactly 10 digits.");
    } else {
      setErrorMessage("");
    }
  };

  const handleSsnValidate = (e) => {
    const { value } = e.target;
    console.log(value, "value")
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (sanitizedValue.split("").length <= 9) {
      setPatientDetails((prevState) => ({ ...prevState, ssn: sanitizedValue }));
    }

    if (sanitizedValue.length !== 9) {
      setErrorSsnMessage("Ssn must be exactly 9 digits.");
    } else {
      setErrorSsnMessage("");
    }
  };
  const handleSsnInsuranceValidate = (e) => {
    const { value } = e.target;
    console.log(value, "value")
    const sanitizedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

    if (sanitizedValue.split("").length <= 9) {
      setPatientInsuranceDetails((prevState) => ({ ...prevState, insuranceSsn: sanitizedValue }));
    }

    if (sanitizedValue.length !== 9) {
      setErrorSsnMessage("Ssn must be exactly 9 digits.");
    } else {
      setErrorSsnMessage("");
    }
  };

    const handleEmailValidate = (e) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^A-Za-z0-9._%+-@]/g, ""); // Remove invalid characters

    setPatientDetails((prevState) => ({ ...prevState, email: sanitizedValue }));

    if (!emailRegex.test(sanitizedValue)) {
      setErrorEmailValidateMessage("Enter a valid email ID.");
    } else {
      setErrorEmailValidateMessage("");
    }
  };

  

  const patientCard = (profileName,patientName,patientId,dob,gender,email,Phone, enroll) => (
    <div className="patientCardCard" onClick={() =>   navigate('/patient-chart', {
      state: {
        patientId, 

      }
    })} key={patientId}>
      <div className="patientCardListCard">
        <div className="d-flex gap-2">
          <h1 className="userNameCard">{profileName}</h1>
          <div className="patientCardCardWidth">
            <h2 className="patientCardHead">{patientName}</h2>
            <p className="patientCardSub">{patientId}</p>
          </div>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Date of Birth/Gender</h2>
          <p className="patientCardSub">{dob}/{gender}</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Email address</h2>
          <p className="patientCardSub">{email}</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Phone</h2>
          <p className="patientCardSub">{Phone}</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Wellness Status</h2>
          <p className="patientCardSub">
             
            {enroll}
          </p>
        </div>
      </div>
    </div>
  );
  const props = {
    name: "file",
    accept: "image/*", // Ensures only image formats are accepted
    beforeUpload: (file) => {
      // Simulate processing the file locally 

      // Simulate a successful upload with a timeout
      setTimeout(() => {
        message.success(`${file.name} file uploaded successfully`);
      }, 1000);

      // Return false to prevent the default upload action
      return false;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log("Uploading:", info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log("Response:", info.file.response);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log("Error:", info.file.error);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onError(err) {
      console.error("Upload error:", err);
    },
  };
  const [open, setOpen] = useState(false);
  const [openAddCommunity, setopenAddCommunity] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const handleCloseAddCommunity = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const getMyPatientData = async () => {
    try {
      // Making API calls in parallel
      const name = searchPatientName;
      const [myPatientResponse] = await Promise.all([
        getMyPatientsService(name, navigate),
      ]);

      setMyPatientList(myPatientResponse); 

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getMyPatientData();
  }, [searchPatientName]); // Re-run when searchPatientName changes

  const handleInputSearchChange = (e) => {
    setSearchPatientName(e.target.value);
  };







  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const addedCommunityMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new Community",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Community Added Successfully",
          1.5,
          setopenAddCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const failedAddingPatientMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding Patient",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Patient added successfully",
          1.5,
          setopenAddCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const [isSchoolCard, isSchoolCardActive] = useState(true);

  const emptySchoolCard = () => (
    <div className="emptySchoolCard">
      <img src={noCommunityCardIcon} alt="noCommunityCardIcon" />
      <p className="noCommunityText">No Community added yet</p>
    </div>
  );
  const handleCloseAddInstructor = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
      setIsBackButtonTriggered(false)
      setSelectedTab("1")
    }
  };

  const addedInstructorMessage = (e) => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new Patient",
        duration: 3,
      })
      .then(() =>
        message.success(
          "Patient Added Successfully",
          1.5,
          setopenAddCommunity(true)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const basicInformation = () => (
    <div className="row row-gap-3 modal_container">
      <h3 className="infoTitle">Basic Information</h3>
      <div className="col-md-4">
        <label className="modal_label">
          First name <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientDetails.patientFirstName}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, patientFirstName: e.target.value.replace(/[^A-Za-z\s]+/g, "")}))
          }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Middle name</label>
        <Input
          className="border-input"
          value={patientDetails.patientMiddleName}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, patientMiddleName: e.target.value.replace(/[^A-Za-z\s]+/g, "")}))
          }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Last name <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientDetails.patientLastName}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, patientLastName: e.target.value.replace(/[^A-Za-z\s]+/g, "")}))
          }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Date of Birth <span className="text-danger">*</span>
        </label>
        <Input
          type="date"
          className="border-input type_date"
          value={patientDetails.patientDOB}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, patientDOB: e.target.value}))}
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Gender <span className="text-danger">*</span>
        </label>
        <Select
          value={patientDetails.selectedGender}
          onChange={handleChangesGender}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {genderList?.map(item => <MenuItem key = {item.genderID} value={item.genderID}>{item.genderDesc}</MenuItem>)}
         
        </Select>
      </div>
      {/* <div className="col-md-4">
        <label className="modal_label">
          Sexual orientation 
        </label>
        <Input
          className="border-input"
          value={patientDetails.sexualOrientation}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState,  sexualOrientation: e.target.value.replace(/[^A-Za-z\s]+/g, "")}))
          }
        />
      </div> */}
      <div className="col-md-4">
        <label className="modal_label">
          Marital Status 
        </label>
        <Input
          className="border-input"
          value={patientDetails.maritalStatus}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, maritalStatus: e.target.value.replace(/[^A-Za-z\s]+/g, "")}))
          }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Race  
        </label>
        <Input
          className="border-input"
          value={patientDetails.race}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, race: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      {/* <div className="col-md-4">
        <label className="modal_label">
          SSN  
        </label>
        <Input
          className="border-input"
          value={patientDetails.ssn}
          maxLength={9} 
          onChange={(e)=> handleSsnValidate(e)} 
        />
         {errorSsnMessage && <div className="error-message">{errorSsnMessage}</div>}
      </div> */}
      <h3 className="infoTitle">Contact information</h3>

      <div className="col-md-4">
        <label className="modal_label">
          Home telephone  
        </label>
        <Input
          className="border-input"
          value={patientDetails.hometelephone}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, hometelephone: e.target.value.replace(/[^0-9.]/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Work telephone 
        </label>
        <Input
          className="border-input"
          value={patientDetails.worktelephone}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, worktelephone: e.target.value.replace(/[^0-9.]/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Mobile <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientDetails.mobile}
          onChange={handleMobileValidate}
        maxLength={10} 
        />
         {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Email <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input email"
          value={patientDetails.email}
          onChange={handleEmailValidate}
        /> 
        {errorEmailValidateMessage && <div className="error-message">{errorEmailValidateMessage}</div>}
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Other telephone 
        </label>
        <Input
          className="border-input"
          value={patientDetails.othertelephone}
          onChange={(e) =>
            setPatientDetails((prevState) => ({...prevState, othertelephone: e.target.value.replace(/[^0-9.]/g, "")})) }
        />
      </div>
      <h3 className="infoTitle   ">Address details</h3>
      <div className="col-md-6">
        <label className="modal_label">
          Address line 1 <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.address1}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, address1: e.target.value} })) }
        />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Address line 2</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.address2}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, address2: e.target.value} })) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">County</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.county}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, county: e.target.value} })) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">City</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.city}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, city: e.target.value} })) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">State</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.state}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, state: e.target.value} })) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Country</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.country}
          onChange={(e) => setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, country: e.target.value} })) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Zip</label>
        <Input
          className="border-input"
          value={patientDetails.PatientAddress.pincode}
          onChange={(e) =>  setPatientDetails((prevState) => ({...prevState, PatientAddress: {...patientDetails.PatientAddress, pincode: e.target.value.replace(/[^0-9]/g, "").slice(0, 6)} })) }
             
            
        />
      </div>
    </div>
  );

  const documents = () => (
    <div className="row row-gap-3   modal_container">
      <div className="col-md-4">
        <label className="modal_label">
          Insurance type <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceType}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, insuranceType: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Insurance category <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceCategory}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, insuranceCategory: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Insurance company <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceCompany}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, insuranceCompany: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Relationship to patient <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.relationshipToPatient}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, relationshipToPatient: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
        
      </div>
      {/* <div className="col-md-4">
        <label className="modal_label">
          SSN <span className="text-danger">*</span>
        </label> 
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceSsn}
          maxLength={9} 
          onChange={(e)=> handleSsnInsuranceValidate(e)} 
        />
         {errorSsnMessage && <div className="error-message">{errorSsnMessage}</div>}
      </div> */}
      <div className="col-md-4">
        <label className="modal_label">
          First Name <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.firstName}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, firstName: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Middle name</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.middleName}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, middleName: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Last name</label> <span className="text-danger">*</span>
        <Input
          className="border-input"
          value={patientInsuranceDetails.lastName}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, lastName: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Gender</label>
        <Select
          value={patientInsuranceDetails.selectedGender}
          onChange={handleChangesGenderInsurance}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {genderList?.map(item => <MenuItem key = {item.genderID} value={item.genderID}>{item.genderDesc}</MenuItem>)}
         
        </Select>
      </div>
      <div className="col-md-4">
        <label className="modal_label">Date of birth</label>
        <Input className="border-input" />
      </div>

      <div className="col-md-4">
        <label className="modal_label">Subscribed insured</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.subscribedInsured}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, subscribedInsured: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Patient insured</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Copay($)</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.copay}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, copay: e.target.value.replace(/[^0-9]/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Member ID</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.policyNo}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, policyNo: e.target.value})) }
        />
      </div>

      <div className="col-md-4">
        <label className="modal_label">Group policy #</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.groupPolicyNo}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, groupPolicyNo: e.target.value.replace(/[^0-9]/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Plan /Program name</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.programName}
          onChange={(e) =>  setPatientInsuranceDetails((prevState) => ({...prevState, programName: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Billing method</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.billingMethod}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, billingMethod: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Effective date</label>
        <Input
          type="date"
          className="border-input"
          value={patientInsuranceDetails.effectiveDate}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, effectiveDate: e.target.value})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">End date</label>
        <Input
          type="date"
          className="border-input"
          value={patientInsuranceDetails.terminationDate}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, terminationDate: e.target.value})) }
        />
      </div>
      <h2 className="infoTitle mb-2">Address details</h2>

      <div className="col-md-6">
        <label className="modal_label">
          Address line 1 <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.addressLine1}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, addressLine1: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Address line 2</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.addressLine2}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, addressLine2: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">County</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceCounty}
          onChange={(e) =>  setPatientInsuranceDetails((prevState) => ({...prevState, insuranceCounty: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">City</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceCity}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, insuranceCity: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
      <div className="col-md-4">
        <label className="modal_label">State</label>
        <Input
          className="border-input"
          value={patientInsuranceDetails.insuranceState}
          onChange={(e) => setPatientInsuranceDetails((prevState) => ({...prevState, insuranceState: e.target.value.replace(/[^A-Za-z\s]+/g, "")})) }
        />
      </div>
    </div>
  );

  const cheifComplaint = () =>(
    <div className="container-fluid">
       {contextHolder}
              <div className="row">
                <div className="col-md-12">
                  <label className="modal_label">Medical condition</label><span className="text-danger">*</span>
                  <br />
                  <Select
                    // value={medicalConditionList}
                    onChange={handleChangeMedicalCondition}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {medicalConditionList?.map((item) => (
                      <MenuItem
                        key={item.healthConditionId}
                        value={item.conditionCode}
                      >
                        {item.medicalConditionName}
                      </MenuItem>
                    ))}
                  </Select>
                  <label className="modal_label">Notes</label>  
                  <br />
                  <TextArea
                    classNames="bgwhite_input"
                    className="border-input no-resize"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={4}
                  />
                </div>
              </div>
              {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
            </div>
  )

  const handleTabChangeEvent = (key) => {
      
      setSelectedTab(key)
  }
  const chiefComplaint = async (e) => {
    const bodyContent = {
      UserHealthConditionID: 0,
      MemberId : patientIdFromApi.id,
      ConditionCode: selectedMedicalCondition,
      Notes : notes,
      
    };
   
    try {
       const response = await postAddCheifComplaintService(
        navigate,
        bodyContent
      );
  
      if (response !== "invalid" && response) { // check if response status is 200-299
        return true;
      } else {
        // Handle error response
        console.error('Failed to add chief complaint', response.statusText);
        return false;
      }
    } catch (error) {
      // Handle network or other errors
      console.error('An error occurred while adding chief complaint', error);
      return false;
    }
  };
  const handleChangeMedicalCondition = (event) => {
    setSelectedMedicalCondition(event.target.value);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const medicalConditionResponse = await getAllMedicalConditionService();
        
        // console.log(getInvitationLinkRes, "invitation link")
        setPatientInsuranceDetails(patientInsuranceDetails) 
        if (
          medicalConditionResponse !== "invalid" &&
          medicalConditionResponse
        ) {
          setMedicalConditionList(medicalConditionResponse); 
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchData();
  }, []);
  const fetchInvitationLink = async () => {
    try {
      const memberID = patientIdFromApi.id
      const medicalConditionResponse = await getInvitationLink(memberID, navigate);
       
      setPatientDetails(patientDetails) 
       
    } catch (e) {
      console.log(e.message);
    }
  };
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const isButtonDisabled = !selectedMedicalCondition || !notes.trim();
  const handleTabButtonEvent = (e) => {
    if (selectedTab === "1") { 
       postPatientDetails(e)
        if (patientIdFromApi.status == "1") { 
        setErrorEmailMessage("email already exist")
        setSelectedTab("1")
        } else { 
       setSelectedTab("2")
       setDisableNextButton(true)
        }
      
        // if (!isBackButtonTriggered) {
         
          
        //   }
         
        //   setDisableNextButton(false)
        //   if (patientInsuranceDetails.insuranceType && patientInsuranceDetails.insuranceCategory && patientInsuranceDetails.insuranceCompany && patientInsuranceDetails.relationshipToPatient && patientInsuranceDetails.insuranceSsn && patientInsuranceDetails.firstName &&  patientInsuranceDetails.addressLine1 ) {
        //     setDisableNextButton(false)
        //   } else {
        //     setDisableNextButton(true)
        //   }
       
      
     
    } else if (selectedTab === "2") {
      setDisableNextButton(false)
      setSelectedTab("3")
      // postInsuranceDetails(e) 
    } else if (selectedTab === "3") { 
      setopenEnroll(true)
      chiefComplaint(e)
      setDisableNextButton(true) 
      // postInsuranceDetails(e)
      e.preventDefault();
      
    }
     
  }

  const handleBackBtnEventHandler = () => {
     if (selectedTab === "2") {
      setDisableNextButton(true)
      setSelectedTab("1")
      setDisableBackButton(true)
      setIsBackButtonTriggered(true)
      if (patientDetails.patientFirstName && patientDetails.patientLastName && patientDetails.patientDOB && patientDetails.selectedGender  &&  patientDetails.mobile && patientDetails.email  && patientDetails.PatientAddress.address1 ) {
        setDisableNextButton(false)
      } else {
        setDisableNextButton(true)
      }
    } else if(selectedTab === "3"){
      setSelectedTab("2")
    }
  }


  const items = [
    {
      key: "1",
      label: "Demographics",
      children: basicInformation(),
    },
    {
      key: "2",
      label: "Insurance details",
      children: documents(),
    },
    {
      key: "3",
      label: "Chief Complaint",
      children: cheifComplaint(),
    },
  ];

  return (
    <div className="d-flex">
      <LeftNavigationBar />
      <div className="rightDashboard_container">
        <RightTopHeaderMenu title="My patients" />
        <div className="d-flex justify-content-between align-items-center head_sticky myPatientSubHeader">
          <div className="searchInputFeild">
          <Input
          className="border-input"
          placeholder="Search patient name "
          prefix={<SearchOutlined />}
          value={searchPatientName} 
          onChange={handleInputSearchChange}
        />
          </div>
          <div className="myPatientSubHeaderBtn">
            <button
              className="addPatient_btn"
              onClick={() => setopenAddCommunity(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.99995 8H12M8.00478 12L7.99512 4"
                  stroke="white"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add patient
            </button>
          </div>
        </div>
        <StickyLabel />
        <div className="schoolsContainer">
          {isSchoolCard ? myPatientList?.map(item =>  patientCard(getFirstAndLastLetters(item.patientFullName), item.patientFullName, item.patientId, item.dateOfBirth, item.genderDesc, item.email, item.mobile, item.wellnessStatus)) : emptySchoolCard()}
        </div>
        <Dialog
          className="right_popup"
          open={openAddCommunity}
          onClose={handleCloseAddInstructor}
          scroll={scroll}
          
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <div>
            <div className="d-flex w-100 align-items-center justify-content-between modal_sticky_top">
              <h1 className="modal_title" >Add Patient</h1>
              <button
                className="btn-none"
                type="button"
                onClick={handleCloseAddInstructor}
              >
                <img src={closeBtnIcon} alt="closeBtnIcon" />
              </button>
            </div>
            {contextHolder}

            <div className="container-fluid">
              <Tabs activeKey={selectedTab} items={items} onChange={() => {}} />
            </div>
          </div>

          <DialogActions>
            <button className="cancel_btn" disabled = {selectedTab === "1"} onClick={handleBackBtnEventHandler}>
              Back
            </button>
            <button
              className="add_btn"
              disabled={disableNextButton}
              onClick={(e) => handleTabButtonEvent(e)}
            >
              {selectedTab === "1" || "2" ? "Next" : "Add"}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog 
        className="sendInviteContainer"
          open={openEnroll}
          onClose={handleCloseEnroll}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <div className="sendInviteContainer">
            <div className="d-flex w-100 align-items-center justify-content-between modal_sticky_top">
              <h1 className="modal_title">Sending Invite</h1>
              <button
                className="btn-none"
                type="button"
                onClick={handleCloseEnroll}
              >
                <img src={closeBtnIcon} alt="closeBtnIcon" />
              </button>
            </div>
            {contextHolder}

             <div className="container-fluid">
             <p className="sendInvite">We are sending you invite for the provided {patientDetails.email}</p>
             {/* <p className="sendInvite">We are sending you invite for the provided {patientDetails.email} and +1 {patientDetails.mobile}</p> */}
             </div>
          </div>

          <DialogActions>
           
            <button className="add_btn violet-bg" onClick={addedEnrollMessage}>
              Add
            </button>
            <button className="cancel_btn" onClick={handleCloseEnroll}>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default MyPatient;
