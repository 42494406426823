import { Dropdown } from "antd";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccountUserDetails } from "../../Service/service";
import { updateJwtToken } from "../../../globalDataManager/UserDetails/userDetails";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RightTopHeaderMenu = ({title}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

 

const [userProfileDetails, setuserProfileDetails] = useState({});
const [userName, setUserName] = useState("")

   
useEffect(() => {
  dispatch(updateJwtToken());
  const getData = async () => {
    const userAccountRes = await getAccountUserDetails(navigate);
    if (userAccountRes !== "invalid" && userAccountRes) {
      setuserProfileDetails(userAccountRes);

      const nameFromUserName = userAccountRes.employeeFullName ? userAccountRes.employeeFullName?.split("")[0] : ""
      const nameFromEmail = userAccountRes?.email ? userAccountRes?.email?.split("")[0] : ""
      setUserName(nameFromUserName ? nameFromUserName : nameFromEmail)
      console.log(userAccountRes, "top menu response");
    }
  };

  getData();
}, []);
const getFirstAndLastLetters = (name) => {
  const words = name.trim().split(' ');
  if (words.length === 0) return ''; // Handle case where name might be empty

  const firstLetter = words[0][0];
  const lastLetter = words[words.length - 1][0];
  console.log(firstLetter , lastLetter)

  return `${firstLetter ?? ""}${lastLetter ?? ""}`;
};


  const handleLogout = () => {
    localStorage.removeItem('Friska_Database'); // Clear the token or relevant data from localStorage
    navigate('/sign-in'); // Redirect to the login page after logout
  };

  const items = [
    {
      key: "1",
      label: (
        <p onClick={() => navigate("/my-account")} className="menu_dropdown_item">
       <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_292_1729)">
<path d="M8.08 9.02048H7.96667C6.45333 8.97381 5.32 7.79381 5.32 6.34048C5.32 4.86048 6.52667 3.65381 8.00667 3.65381C9.48667 3.65381 10.6933 4.86048 10.6933 6.34048C10.6947 7.03679 10.4246 7.70622 9.9404 8.20662C9.45618 8.70702 8.79599 8.99897 8.1 9.02048H8.08ZM8 4.64714C7.06667 4.64714 6.31333 5.40714 6.31333 6.33381C6.31132 6.77032 6.47929 7.19048 6.78167 7.5053C7.08405 7.82012 7.4971 8.00489 7.93333 8.02048C7.9842 8.01344 8.0358 8.01344 8.08667 8.02048C8.51853 7.99744 8.92513 7.80973 9.22276 7.49597C9.5204 7.18221 9.68643 6.76628 9.68667 6.33381C9.68667 5.40714 8.93333 4.64714 8 4.64714ZM8 15.1671C6.20667 15.1671 4.49333 14.5005 3.16667 13.2871C3.10928 13.2343 3.06495 13.1689 3.03719 13.096C3.00942 13.0231 2.99897 12.9448 3.00667 12.8671C3.09333 12.0738 3.58667 11.3338 4.40667 10.7871C6.39333 9.46714 9.61333 9.46714 11.5933 10.7871C12.4133 11.3405 12.9067 12.0738 12.9933 12.8671C13.0133 13.0271 12.9533 13.1805 12.8333 13.2871C11.5148 14.4975 9.7898 15.1684 8 15.1671ZM4.05333 12.7338C5.15951 13.6606 6.55686 14.1681 8 14.1671C9.44667 14.1671 10.84 13.6605 11.9467 12.7338C11.8267 12.3271 11.5067 11.9338 11.0333 11.6138C9.39333 10.5205 6.61333 10.5205 4.96 11.6138C4.48667 11.9338 4.17333 12.3271 4.05333 12.7338Z" fill="#475569" stroke="#475569" strokeWidth="0.6"/>
<path d="M8.00001 15.1673C4.04668 15.1673 0.833344 11.954 0.833344 8.00065C0.833344 4.04732 4.04668 0.833984 8.00001 0.833984C11.9533 0.833984 15.1667 4.04732 15.1667 8.00065C15.1667 11.954 11.9533 15.1673 8.00001 15.1673ZM8.00001 1.83398C4.60001 1.83398 1.83334 4.60065 1.83334 8.00065C1.83334 11.4007 4.60001 14.1673 8.00001 14.1673C11.4 14.1673 14.1667 11.4007 14.1667 8.00065C14.1667 4.60065 11.4 1.83398 8.00001 1.83398Z" fill="#475569" stroke="#475569" strokeWidth="0.6"/>
</g>
<defs>
<clipPath id="clip0_292_1729">
<rect width="16" height="16" fill="white" transform="translate(0 0.000488281)"/>
</clipPath>
</defs>
</svg>

        My Account
      </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="menu_dropdown_item_logout" onClick={() => handleLogout()}>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.77137 9.74634L10.478 8.03967L8.77137 6.33301M3.65137 8.03967H10.4314"
              stroke="#E81C28"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.98438 2.66699C11.931 2.66699 14.3177 4.66699 14.3177 8.00033C14.3177 11.3337 11.931 13.3337 8.98438 13.3337"
              stroke="#E81C28"
              strokeWidth="1.2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Log out
        </p>
      ),
    },
  ];
  return (
    <div className="RightTopHeaderContainer">
    <div className="d-flex gap-2 align-items-center">
        {/* <button className="btn-none"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 18H21" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 12H21" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3 6H21" stroke="#64748B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button> */}
<h2 className="headerTitle">{title}</h2>
    </div>
    <div className="d-flex gap-4 align-items-center">
        {/* <button className="btn-none"><svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3617 3.2334C7.50827 3.2334 5.1893 5.55236 5.1893 8.40581V10.8972C5.1893 11.4231 4.96516 12.2248 4.69792 12.6731L3.70654 14.3196C3.09447 15.3368 3.51689 16.4662 4.63758 16.8455C8.35309 18.0868 12.3617 18.0868 16.0772 16.8455C17.1203 16.5006 17.5772 15.2679 17.0083 14.3196L16.0169 12.6731C15.7583 12.2248 15.5341 11.4231 15.5341 10.8972V8.40581C15.5341 5.56098 13.2065 3.2334 10.3617 3.2334Z" stroke="#64748B" strokeWidth="1.72414" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M11.9568 3.48367C11.6896 3.40608 11.4137 3.34574 11.1293 3.31125C10.3017 3.20781 9.50856 3.26815 8.76718 3.48367C9.01718 2.84574 9.63787 2.39746 10.362 2.39746C11.0861 2.39746 11.7068 2.84574 11.9568 3.48367Z" stroke="#64748B" strokeWidth="1.72414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.9479 17.1553C12.9479 18.5777 11.7841 19.7415 10.3617 19.7415C9.65476 19.7415 8.99959 19.4484 8.53407 18.9829C8.06855 18.5173 7.77545 17.8622 7.77545 17.1553" stroke="#64748B" strokeWidth="1.72414" strokeMiterlimit="10"/>
</svg>
<span className="notificationBadge">2</span>
</button>
<button className="btn-none">
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_197_718)">
<path d="M9.99507 20.207C4.4673 20.2028 -0.00423012 15.7263 -2.75577e-05 10.2021C0.00417501 4.67233 4.4785 0.202832 10.0049 0.207034C15.5354 0.211236 20.0042 4.68354 20 10.2119C19.9958 15.7403 15.52 20.2126 9.99507 20.2084V20.207ZM1.99759 10.4669C2.16569 14.9714 5.87235 18.3372 10.2262 18.2055C14.8112 18.0668 18.1466 14.2752 17.9996 9.94581C17.8469 5.4567 14.136 2.06011 9.74011 2.20858C5.24197 2.35985 1.86591 6.07999 1.99759 10.4669Z" fill="#64748B"/>
<path d="M7.97638 8.18334H6.01939C5.98577 7.34995 6.24213 6.6188 6.6918 5.95489C7.71443 4.44638 9.6336 3.82309 11.337 4.42817C13.0629 5.04026 14.15 6.73646 13.9762 8.57412C13.902 9.36129 13.4817 9.99019 12.9606 10.5519C12.6132 10.9258 12.2392 11.276 11.8778 11.6402C11.4477 12.073 11.0471 12.524 10.9952 13.1879H9.01862C8.9878 12.4904 9.19933 11.8811 9.635 11.3782C10.0525 10.8978 10.5329 10.4706 10.9896 10.0252C11.4421 9.584 11.8792 9.14139 11.9758 8.47047C12.1257 7.42698 11.3763 6.3919 10.3396 6.23082C9.25116 6.06274 8.22574 6.77568 8.04082 7.83037C8.02121 7.94383 7.9988 8.05588 7.97638 8.18194V8.18334Z" fill="#64748B"/>
<path d="M9.02017 16.1827V14.2246H10.9646V16.1827H9.02017Z" fill="#64748B"/>
</g>
<defs>
<clipPath id="clip0_197_718">
<rect width="20" height="20" fill="white" transform="translate(0 0.207031)"/>
</clipPath>
</defs>
</svg>

</button> */}
 
<Dropdown
          menu={{
            items,
          }}
          placement="topLeft"
        >
          <div className="btn-none d-flex align-items-center gap-2 cursor-pointer">
          <h5 className="userNameTextBadge">{getFirstAndLastLetters(userName ?? "") }</h5>
    <p className="userNameText">{userProfileDetails?.employeeFullName ? userProfileDetails?.employeeFullName : userProfileDetails?.email  ?? ""}</p>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 6L8 10L12 6" stroke="#04021D" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

          </div>
        </Dropdown>

    </div>
  </div>
  )
};

export default RightTopHeaderMenu;
