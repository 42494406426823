import LeftNavigationBar from "../../shareableResources/LeftNavigationBar";
import "./index.css";
import backIcon from "../../../assets/images/rightContainer/backIcon.svg";
import plusIcon from "../../../assets/images/rightContainer/plusIcon.svg";
import editSchoolIcon from "../../../assets/images/rightContainer/editSchoolIcon.svg";
import removeSchoolIcon from "../../../assets/images/rightContainer/removeSchoolIcon.svg";
import closeBtnIcon from "../../../assets/images/rightContainer/closeBtnIcon.svg";
import { useNavigate } from "react-router-dom";
import StickyLabel from "../../shareableResources/StickyLabel";
import noSchoolListIcon from "../../../assets/images/rightContainer/noSchoolListIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Badge, Input, message, Modal, Pagination, Space, Tabs } from "antd";
import { DownOutlined } from "@ant-design/icons";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useRef, useState } from "react";
import { Button, Upload } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { Dropdown } from "primereact/dropdown";
import videoTemp from "../../../assets/images/rightContainer/videoTemp.svg";
import instructorProfilePic from "../../../assets/images/rightContainer/instructorProfilePic.svg";
import { SearchOutlined } from "@ant-design/icons";
import Menu from "@mui/material/Menu";
import * as React from "react";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import TextArea from "antd/es/input/TextArea";
import { FileUpload } from "primereact/fileupload";
import { UploadOutlined } from "@ant-design/icons";
import { AudioOutlined } from "@ant-design/icons";
import { CheckCircleOutlined } from "@ant-design/icons";
import videoInfoProfilePic from "../../../assets/images/rightContainer/videoInfoProfilePic.svg"

const { Search } = Input;

const options = [
  {
    label: "Musculoskeletal condition",
    value: "Musculoskeletal condition",
  },
  {
    label: "Emotional & Mental Health",
    value: "Emotional & Mental Health",
  },
  {
    label: "Sleep Disorders",
    value: "Sleep Disorders",
  },
  {
    label: "Over weight / Obesity",
    value: "Over weight / Obesity",
  },
  {
    label: "Diabetes / Metabolic Syndromes",
    value: "Diabetes / Metabolic Syndromes",
  },
];
const level = [
  {
    label: "Beginner",
    value: "Beginner",
  },
  {
    label: "Intermediate",
    value: "Intermediate",
  },
];

const MyLibrary = () => {
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1677ff",
      }}
    />
  );
  const navigate = useNavigate();
  const [openEditCommunity, setopenEditCommunity] = useState(false);
  const handleCloseAddInstructor = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
    }
  };
  const addedCommunityMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing Community",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Community Edited Successfully",
          1.5,
          setopenEditCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const props = {
    name: "file",
    accept: "image/*", // Ensures only image formats are accepted
    beforeUpload: (file) => {
      // Simulate processing the file locally
      console.log("File before upload:", file);

      // Simulate a successful upload with a timeout
      setTimeout(() => {
        message.success(`${file.name} file uploaded successfully`);
      }, 1000);

      // Return false to prevent the default upload action
      return false;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log("Uploading:", info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log("Response:", info.file.response);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log("Error:", info.file.error);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onError(err) {
      console.error("Upload error:", err);
    },
  };
  const addedInstructorMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing new Instructor",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Instructor Edited Successfully",
          1.5,
          setopenAddCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const basicInformation = () => (
    <div className="row row-gap-3 mt-3">
      <div className="col-md-6">
        <label className="modal_label">
          First name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Last name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Email address <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Mobile <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Country</label>
        <br />
        <Select
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">State</label>
        <br />
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">City</label>
        <br />
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Zip code <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>

      <div className="col-12">
        <h2 className="instructorSubTitle mb-2">Social links</h2>
        <hr className="mt-0" />
        <div className="d-flex align-items-center w-100">
          <p className="socialLink">Linkedin</p>
          <Input className="border-input" placeholder="paste your link here" />
        </div>
        <div className="d-flex align-items-center w-100 mt-3">
          <p className="socialLink">Facebook</p>
          <Input className="border-input" placeholder="paste your link here" />
        </div>
        <div className="d-flex align-items-center w-100 mt-3">
          <p className="socialLink">Instagram</p>
          <Input className="border-input" placeholder="paste your link here" />
        </div>
        <div className="d-flex align-items-center w-100 mt-3">
          <p className="socialLink">Website</p>
          <Input className="border-input" placeholder="paste your link here" />
        </div>
      </div>
    </div>
  );

  const documentsList = () => (
    <div className="row row-gap-3 mt-3">
      <div className="col12">
        <h2 className="instructorSubTitle mb-2">Social links</h2>
        <hr className="mt-0" />
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Basic information",
      children: basicInformation(),
    },
    {
      key: "2",
      label: "Documents",
      children: documentsList(),
    },
  ];
  const reportsList = (reports) => (
    <div className="instructorList">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="5.95745" fill="#DFF5E6" />
        <path
          d="M29 26.75H28.25V11.75C28.25 11.5511 28.171 11.3603 28.0303 11.2197C27.8897 11.079 27.6989 11 27.5 11H22.25C22.0511 11 21.8603 11.079 21.7197 11.2197C21.579 11.3603 21.5 11.5511 21.5 11.75V15.5H17C16.8011 15.5 16.6103 15.579 16.4697 15.7197C16.329 15.8603 16.25 16.0511 16.25 16.25V20H12.5C12.3011 20 12.1103 20.079 11.9697 20.2197C11.829 20.3603 11.75 20.5511 11.75 20.75V26.75H11C10.8011 26.75 10.6103 26.829 10.4697 26.9697C10.329 27.1103 10.25 27.3011 10.25 27.5C10.25 27.6989 10.329 27.8897 10.4697 28.0303C10.6103 28.171 10.8011 28.25 11 28.25H29C29.1989 28.25 29.3897 28.171 29.5303 28.0303C29.671 27.8897 29.75 27.6989 29.75 27.5C29.75 27.3011 29.671 27.1103 29.5303 26.9697C29.3897 26.829 29.1989 26.75 29 26.75ZM23 12.5H26.75V26.75H23V12.5ZM17.75 17H21.5V26.75H17.75V17ZM13.25 21.5H16.25V26.75H13.25V21.5Z"
          fill="#176D6F"
        />
      </svg>

      <h2 className="instructorHead">{reports}</h2>
    </div>
  );

  const listOfSchools = (title, sub) => (
    <div className="d-flex">
      <p className="schoolList_sub schoolList_sub_width ">{title}</p>
      <p className="schoolList_title">{sub}</p>
    </div>
  );
  const [open, setOpen] = useState(false);
  const [openEditSchool, setOpenEditSchool] = useState(false);
  const [openVideoInformation, setopenVideoInformation] = useState(false);
  const [openAddCommunity, setopenAddCommunity] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const addNewSchoolOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const addNewSchoolClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const editSchoolOpen = (scrollType) => () => {
    setOpenEditSchool(true);
    setScroll(scrollType);
  };

  const editSchoolClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenEditSchool(false);
    }
  };
  const setopenVideoInformationOpen = (scrollType) => () => {
    setopenVideoInformation(true);
    setScroll(scrollType);
  };

  const setopenVideoInformationClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenVideoInformation(false);
    }
  };
  const handleCloseAddCommunity = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const addedSchoolMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new School",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "School has been added successfully.",
          4.5,
          setOpen(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };
  const editeddSchoolMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing School Details",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "School has been edited successfully.",
          4.5,
          setOpenEditSchool(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };

  const [deleteSchoolModalOpen, deleteSchoolOpen] = useState(false);
  const removeSchool = () => {
    messageApi
      .open({
        type: "loading",
        content: "Deleting..",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Video been deleted successfully.",
          4.5,
          deleteSchoolOpen(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };

  const [isSchoolCard, isSchoolCardActive] = useState(true);

  const emptyInstructorCard = () => (
    <div className="emptySchoolCard">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="5.95745" fill="#DFF5E6" />
        <path
          d="M29 26.75H28.25V11.75C28.25 11.5511 28.171 11.3603 28.0303 11.2197C27.8897 11.079 27.6989 11 27.5 11H22.25C22.0511 11 21.8603 11.079 21.7197 11.2197C21.579 11.3603 21.5 11.5511 21.5 11.75V15.5H17C16.8011 15.5 16.6103 15.579 16.4697 15.7197C16.329 15.8603 16.25 16.0511 16.25 16.25V20H12.5C12.3011 20 12.1103 20.079 11.9697 20.2197C11.829 20.3603 11.75 20.5511 11.75 20.75V26.75H11C10.8011 26.75 10.6103 26.829 10.4697 26.9697C10.329 27.1103 10.25 27.3011 10.25 27.5C10.25 27.6989 10.329 27.8897 10.4697 28.0303C10.6103 28.171 10.8011 28.25 11 28.25H29C29.1989 28.25 29.3897 28.171 29.5303 28.0303C29.671 27.8897 29.75 27.6989 29.75 27.5C29.75 27.3011 29.671 27.1103 29.5303 26.9697C29.3897 26.829 29.1989 26.75 29 26.75ZM23 12.5H26.75V26.75H23V12.5ZM17.75 17H21.5V26.75H17.75V17ZM13.25 21.5H16.25V26.75H13.25V21.5Z"
          fill="#176D6F"
        />
      </svg>

      <p className="noCommunityText">No Report list</p>
    </div>
  );

  const onFilterChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const [levels, setLevels] = useState({
    Beginner: "", // Example initial count
    Intermediate: "", // Example initial count
  });

  const levelBadge = level.map((condition) => ({
    ...condition,
    label: (
      <span>
        {condition.label}
        <Badge
          count={levels[condition.value]}
          style={{ backgroundColor: "#52c41a", marginLeft: 8 }}
        />
      </span>
    ),
  }));

  const medicalCondition = [
    {
      label: "Musculoskeletal condition",
      value: "MusculoskeletalCondition",
    },
    {
      label: "Emotional & Mental Health",
      value: "EmotionalMentalHealth",
    },
    {
      label: "Sleep Disorders",
      value: "SleepDisorders",
    },
    {
      label: "Over weight / Obesity",
      value: "OverWeightObesity",
    },
    {
      label: "Diabetes / Metabolic Syndromes",
      value: "DiabetesMetabolicSyndromes",
    },
  ];

  const [medCondition, setMedCondition] = useState({
    MusculoskeletalCondition: 10,
    EmotionalMentalHealth: 5,
    SleepDisorders: 10,
    OverWeightObesity: 5,
    DiabetesMetabolicSyndromes: 10,
  });

  const conditionBadge = medicalCondition.map((condition) => ({
    ...condition,
    label: (
      <span className="filterLabel">
        {condition.label}
        <Badge
          count={medCondition[condition.value]}
          style={{
            backgroundColor: "rgba(7, 155, 158, 0.15)",
            marginLeft: 8,
            borderRadius: "4px",
            color: "#079B9E",
            width: "26px",
            textAlign: "center",
          }}
        />
      </span>
    ),
  }));
  const practices = [
    {
      label: "Asana",
      value: "Asana",
    },
    {
      label: "Pranayama",
      value: "Pranayama",
    },
    {
      label: "Meditation",
      value: "Meditation",
    },
    {
      label: "Relaxation",
      value: "Relaxation",
    },
  ];

  const [practiceCondition, setpractice] = useState({
    Asana: 21,
    Pranayama: 15,
    Meditation: 10,
    Relaxation: 5,
  });

  const practiceBadge = practices.map((condition) => ({
    ...condition,
    label: (
      <span className="filterLabel">
        {condition.label}
        <Badge
          count={practiceCondition[condition.value]}
          style={{
            backgroundColor: "rgba(7, 155, 158, 0.15)",
            marginLeft: 8,
            borderRadius: "4px",
            color: "#079B9E",
            width: "26px",
            textAlign: "center",
          }}
        />
      </span>
    ),
  }));

  const [city, setCity] = useState();

  const citySelectItems = [
    { label: "Newest first", value: "NY" },
    { label: "Oldest first", value: "RM" },
    { label: "A-Z", value: "LDN" },
    { label: "Z-A", value: "IST" },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMore = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const videoList = (
    duration,
    videoTitle,
    videoSub,
    condition,
    instrucorName,
    uploadData
  ) => (
    <div className="d-flex justify-content-between videoListContainer">
      <div className="d-flex gap-2">
        <div className="videoTempContainer">
          <img className="videoTempImg" src={videoTemp} alt="videoTemp" />

          <svg
            className="playIcon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#222222" fillOpacity="0.42" />
            <path
              d="M18.123 12.0003C18.1234 12.1488 18.0853 12.2949 18.0125 12.4244C17.9397 12.5539 17.8346 12.6623 17.7074 12.7391L9.82805 17.5593C9.6952 17.6406 9.54305 17.685 9.38731 17.6879C9.23156 17.6908 9.07787 17.652 8.94211 17.5757C8.80764 17.5005 8.69562 17.3908 8.61757 17.258C8.53953 17.1252 8.49827 16.9739 8.49805 16.8199V7.18066C8.49827 7.0266 8.53953 6.87538 8.61757 6.74255C8.69562 6.60971 8.80764 6.50007 8.94211 6.42488C9.07787 6.34851 9.23156 6.30977 9.38731 6.31265C9.54305 6.31553 9.6952 6.35994 9.82805 6.44129L17.7074 11.2614C17.8346 11.3383 17.9397 11.4467 18.0125 11.5761C18.0853 11.7056 18.1234 11.8517 18.123 12.0003Z"
              fill="white"
            />
          </svg>

          <p className="duration">{duration}</p>
        </div>
        <div>
          <h3 className="videoTitle">{videoTitle}</h3>
          <p className="videoSub">{videoSub}</p>
          <div className="d-flex flex-wrap gap-2">
            <p className="conditionBullet">
              <svg
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2.5" cy="2.5" r="2.5" fill="#1E293B" />
              </svg>
              {condition}
            </p>
          </div>
        </div>
      </div>
      <div className="instructorContainer">
        <div className="d-flex gap-2 alig-items-center">
          <img
            className="instructorProfilePic"
            src={instructorProfilePic}
            alt="instructorProfilePic"
          />
          <div>
            <h5 className="instrucorName">{instrucorName}</h5>
            <p className="uploadData">{uploadData}</p>
          </div>
        </div>
        <button
          className="btn-none ms-5"
          id="basic-button"
          aria-controls={openMore ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMore ? "true" : undefined}
          onClick={handleClick}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.33594 15.8333C8.33594 16.75 9.08594 17.5 10.0026 17.5C10.9193 17.5 11.6693 16.75 11.6693 15.8333C11.6693 14.9167 10.9193 14.1667 10.0026 14.1667C9.08594 14.1667 8.33594 14.9167 8.33594 15.8333Z"
              fill="#1E293B"
              stroke="#1E293B"
              strokeWidth="0.25"
            />
            <path
              d="M8.33594 4.16536C8.33594 5.08203 9.08594 5.83203 10.0026 5.83203C10.9193 5.83203 11.6693 5.08203 11.6693 4.16536C11.6693 3.2487 10.9193 2.4987 10.0026 2.4987C9.08594 2.4987 8.33594 3.2487 8.33594 4.16536Z"
              fill="#1E293B"
              stroke="#1E293B"
              strokeWidth="0.25"
            />
            <path
              d="M8.33594 10.0013C8.33594 10.918 9.08594 11.668 10.0026 11.668C10.9193 11.668 11.6693 10.918 11.6693 10.0013C11.6693 9.08464 10.9193 8.33464 10.0026 8.33464C9.08594 8.33464 8.33594 9.08464 8.33594 10.0013Z"
              fill="#1E293B"
              stroke="#1E293B"
              strokeWidth="0.25"
            />
          </svg>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMore}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={setopenVideoInformationOpen("paper")}>
            <svg
              className="me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_1775_19026)">
                <path
                  d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z"
                  stroke="#475569"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10.6667V8"
                  stroke="#475569"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 5.33398H8.00667"
                  stroke="#475569"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1775_19026">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            View Info
          </MenuItem>
          <MenuItem onClick={editSchoolOpen("paper")}>
            <svg
              className="me-3"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.79313 14.0005H3.00001C2.73479 14.0005 2.48043 13.8951 2.2929 13.7076C2.10536 13.52 2.00001 13.2657 2.00001 13.0005V10.2073C1.99959 10.076 2.02528 9.94584 2.0756 9.82448C2.12593 9.70313 2.19987 9.593 2.29313 9.50047L5.79313 14.0005ZM5.79313 14.0005C5.9245 14.0009 6.05464 13.9752 6.17599 13.9249M5.79313 14.0005L6.17599 13.9249M6.17599 13.9249C6.29735 13.8746 6.40748 13.8006 6.50001 13.7073L6.17599 13.9249ZM14.1848 4.60819L14.2069 4.5861L14.1848 4.6082C14.2748 4.69816 14.3461 4.80496 14.3948 4.92251C14.4435 5.04006 14.4686 5.16605 14.4686 5.29329C14.4686 5.42052 14.4435 5.54651 14.3948 5.66406C14.3461 5.78161 14.2748 5.88841 14.1848 5.97837L14.2069 6.00047L14.1848 5.97838L6.47791 13.6853L6.47782 13.6853C6.38822 13.7757 6.28155 13.8473 6.16402 13.896C6.0465 13.9447 5.92046 13.9696 5.79323 13.9692H5.79313H3.00001C2.74308 13.9692 2.49667 13.8672 2.315 13.6855C2.13332 13.5038 2.03126 13.2574 2.03126 13.0005L2.03126 10.2073L2.03125 10.2072C2.03085 10.08 2.05574 9.95398 2.10447 9.83646C2.15321 9.71893 2.22482 9.61226 2.31514 9.52266L2.31523 9.52257L10.0221 1.81507L10 1.79297L10.0221 1.81507C10.1121 1.72509 10.2189 1.65371 10.3364 1.60501C10.454 1.55631 10.58 1.53125 10.7072 1.53125C10.8344 1.53125 10.9604 1.55631 11.078 1.60501C11.1955 1.65371 11.3023 1.72509 11.3923 1.81507L14.1848 4.60819ZM5.79313 13.0317H5.80607L5.81523 13.0226L11.3152 7.52257L11.3373 7.50047L11.3152 7.47838L8.5221 4.68525L8.50001 4.66315L8.47791 4.68525L2.97791 10.1853L2.96876 10.1944V10.2073V13.0005V13.0317H3.00001H5.79313ZM11.9779 6.81507L12 6.83717L12.0221 6.81507L13.5221 5.31507L13.5442 5.29297L13.5221 5.27087L10.729 2.47837L10.7069 2.45628L10.6848 2.47838L9.18478 3.97838L9.16268 4.00048L9.18479 4.02257L11.9779 6.81507Z"
                fill="#475569"
                stroke="#475569"
                strokeWidth="0.0625"
              />
            </svg>
            Edit Video
          </MenuItem>
          <MenuItem onClick={() => deleteSchoolOpen(true)}>
            <svg
              className="me-3"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 3H11.25V2.25C11.25 1.78587 11.0656 1.34075 10.7374 1.01256C10.4092 0.684374 9.96413 0.5 9.5 0.5H6.5C6.03587 0.5 5.59075 0.684374 5.26256 1.01256C4.93437 1.34075 4.75 1.78587 4.75 2.25V3H2.5C2.30109 3 2.11032 3.07902 1.96967 3.21967C1.82902 3.36032 1.75 3.55109 1.75 3.75C1.75 3.94891 1.82902 4.13968 1.96967 4.28033C2.11032 4.42098 2.30109 4.5 2.5 4.5H2.75V13C2.75 13.3315 2.8817 13.6495 3.11612 13.8839C3.35054 14.1183 3.66848 14.25 4 14.25H12C12.3315 14.25 12.6495 14.1183 12.8839 13.8839C13.1183 13.6495 13.25 13.3315 13.25 13V4.5H13.5C13.6989 4.5 13.8897 4.42098 14.0303 4.28033C14.171 4.13968 14.25 3.94891 14.25 3.75C14.25 3.55109 14.171 3.36032 14.0303 3.21967C13.8897 3.07902 13.6989 3 13.5 3ZM6.25 2.25C6.25 2.1837 6.27634 2.12011 6.32322 2.07322C6.37011 2.02634 6.4337 2 6.5 2H9.5C9.5663 2 9.62989 2.02634 9.67678 2.07322C9.72366 2.12011 9.75 2.1837 9.75 2.25V3H6.25V2.25ZM11.75 12.75H4.25V4.5H11.75V12.75ZM7.25 6.5V10.5C7.25 10.6989 7.17098 10.8897 7.03033 11.0303C6.88968 11.171 6.69891 11.25 6.5 11.25C6.30109 11.25 6.11032 11.171 5.96967 11.0303C5.82902 10.8897 5.75 10.6989 5.75 10.5V6.5C5.75 6.30109 5.82902 6.11032 5.96967 5.96967C6.11032 5.82902 6.30109 5.75 6.5 5.75C6.69891 5.75 6.88968 5.82902 7.03033 5.96967C7.17098 6.11032 7.25 6.30109 7.25 6.5ZM10.25 6.5V10.5C10.25 10.6989 10.171 10.8897 10.0303 11.0303C9.88968 11.171 9.69891 11.25 9.5 11.25C9.30109 11.25 9.11032 11.171 8.96967 11.0303C8.82902 10.8897 8.75 10.6989 8.75 10.5V6.5C8.75 6.30109 8.82902 6.11032 8.96967 5.96967C9.11032 5.82902 9.30109 5.75 9.5 5.75C9.69891 5.75 9.88968 5.82902 10.0303 5.96967C10.171 6.11032 10.25 6.30109 10.25 6.5Z"
                fill="#475569"
              />
            </svg>
            Delete Video
          </MenuItem>
        </Menu>
      </div>
    </div>
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangee = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const customUploadHandler = ({ files, options }) => {
    // Example: Upload each file
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);

      fetch("./upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              console.log("Upload successful:", data);
              // Mark the upload as complete
              options.clear();
            });
          } else {
            console.error("Upload failed");
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    });
  };
  const uploadProps = {
    name: "file",
    multiple: false,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    accept: "video/*",
    listType: "picture",
    maxCount: 1,
    beforeUpload: (file) => {
      const isVideo = file.type.startsWith("video/");
      if (!isVideo) {
        message.error(`${file.name} is not a video file`);
      }
      return isVideo || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="d-flex">
      <LeftNavigationBar />
      <div className="rightCommunityList_container">
        <div className="schoolList_container">
          <div className="schoolListTitle_Contianer">
            {/* <button
              className="btn-none"
              type="button"
              onClick={() => navigate("/instructor")}
            >
              <img src={backIcon} alt="backIcon" />
            </button> */}
            <div>
              <h2 className="schoolListContianer_title text-nowrap">
                My library <span className="videosCount">44</span>
              </h2>
              <p className="schoolListContianer_sub">Your session videos</p>
            </div>
          </div>
          {/* {isSchoolCard ? reportsList("Age & Gender") : emptyInstructorCard()} */}
          <h3 className="filterTxt">Filters</h3>
          <div className="filterList">
            <h4 className="filterTitle">Level</h4>
            <Checkbox.Group
              className="d-flex flex-column row-gap-2"
              options={levelBadge}
              onChange={onFilterChange}
            />
            <h4 className="filterTitle">Medical Condition</h4>
            <Checkbox.Group
              className="d-flex flex-column row-gap-2"
              options={conditionBadge}
              onChange={onFilterChange}
            />
            <h4 className="filterTitle">Practice</h4>
            <Checkbox.Group
              className="d-flex flex-column row-gap-2"
              options={practiceBadge}
              onChange={onFilterChange}
            />
          </div>
          <StickyLabel />
        </div>
        <div className="schoolDetails_container">
          <div className="searchFilterContainer">
            <Input
              className="border-input videoInputMaxWidth"
              prefix={<SearchOutlined />}
              placeholder="Search videos"
            />
            <div className="d-flex gap-3 align-items-center">
              <Dropdown
                className="sortDropdownBtn"
                value={city}
                options={citySelectItems}
                onChange={(e) => setCity(e.value)}
                placeholder="Sort"
              />

              <button
                className="videUploadBtn"
                onClick={addNewSchoolOpen("paper")}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_885_54178)">
                    <path
                      d="M7.5013 13.8333H12.5013V8.83333H15.8346L10.0013 3L4.16797 8.83333H7.5013V13.8333ZM4.16797 15.5H15.8346V17.1667H4.16797V15.5Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_885_54178">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                Upload video
              </button>
              <Dialog
                className="center_popup"
                open={open}
                onClose={addNewSchoolClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h1 className="modal_title">Upload Video</h1>
                    </div>
                    <div className="col-6 p-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <h1 className="modal_title">Media</h1>
                        <button
                          className="btn-none"
                          type="button"
                          onClick={addNewSchoolClose}
                        >
                          <img src={closeBtnIcon} alt="closeBtnIcon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 videoUploadMinHeight">
                      <div className="col-md-12">
                        <label className="modal_label">Title</label>
                        <Input className="border-input" />
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Level</label>
                        <br />
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Health Condition</label>
                        <br />
                        <InputLabel
                          className="w-100"
                          id="demo-multiple-chip-label"
                        ></InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={personName}
                          onChange={handleChangee}
                          input={<OutlinedInput id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Practice</label>
                        <br />
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Description</label>
                        <TextArea className="border-input no-resize" rows={4} />
                      </div>
                      <div className="col-12">
                        <label className="modal_label">
                          Feedback Questions
                        </label>
                        <Input
                          className="border-input"
                          placeholder="Write your question here"
                        />
                        <TextArea
                          className="border-input no-resize mt-3"
                          placeholder="Write your explanation here"
                          rows={1}
                        />
                        <div className="d-flex align-items-center gap-3 mt-3 w-100">
                          <p className="options">A</p>
                          <Search
                            className="white-input w-100"
                            placeholder="input search text"
                            style={{
                              width: 200,
                            }}
                            enterButton={<CheckCircleOutlined />}
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-3 w-100">
                          <p className="options">B</p>
                          <Search
                            className="white-input w-100"
                            placeholder="input search text"
                            style={{
                              width: 200,
                            }}
                            enterButton={<CheckCircleOutlined />}
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-3 addQuestionContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM18 12.75H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z" fill="#64748B"/>
</svg>
<p className="addQuestion">Add new Question</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 videoUploadMinHeight">
                      <div className="col-12">
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>
                            Upload Video
                          </Button>
                        </Upload>
                      </div>
                    </div>
                  </div>
                </div>

                <DialogActions>
                  <button className="cancel_btn" onClick={addNewSchoolClose}>
                    Cancel
                  </button>
                  <button className="add_btn" onClick={addedSchoolMessage}>
                    Add
                  </button>
                </DialogActions>
              </Dialog>
              <Dialog
                className="center_popup"
                open={openEditSchool}
                onClose={editSchoolClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              > 
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h1 className="modal_title">Edit Video</h1>
                    </div>
                    <div className="col-6 p-0">
                      <div className="d-flex justify-content-between align-items-center">
                        <h1 className="modal_title">Media</h1>
                        <button
                          className="btn-none"
                          type="button"
                          onClick={editSchoolClose}
                        >
                          <img src={closeBtnIcon} alt="closeBtnIcon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 videoUploadMinHeight">
                      <div className="col-md-12">
                        <label className="modal_label">Title</label>
                        <Input className="border-input" />
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Level</label>
                        <br />
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Health Condition</label>
                        <br />
                        <InputLabel
                          className="w-100"
                          id="demo-multiple-chip-label"
                        ></InputLabel>
                        <Select
                          className="w-100"
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={personName}
                          onChange={handleChangee}
                          input={<OutlinedInput id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Practice</label>
                        <br />
                        <Select
                          value={age}
                          onChange={handleChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </div>
                      <div className="col-md-12">
                        <label className="modal_label">Description</label>
                        <TextArea className="border-input no-resize" rows={4} />
                      </div>
                      <div className="col-12">
                        <label className="modal_label">
                          Feedback Questions
                        </label>
                        <Input
                          className="border-input"
                          placeholder="Write your question here"
                        />
                        <TextArea
                          className="border-input no-resize mt-3"
                          placeholder="Write your explanation here"
                          rows={1}
                        />
                        <div className="d-flex align-items-center gap-3 mt-3 w-100">
                          <p className="options">A</p>
                          <Search
                            className="white-input w-100"
                            placeholder="input search text"
                            style={{
                              width: 200,
                            }}
                            enterButton={<CheckCircleOutlined />}
                          />
                        </div>
                        <div className="d-flex align-items-center gap-3 mt-3 w-100">
                          <p className="options">B</p>
                          <Search
                            className="white-input w-100"
                            placeholder="input search text"
                            style={{
                              width: 200,
                            }}
                            enterButton={<CheckCircleOutlined />}
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-3 addQuestionContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM18 12.75H12.75V18C12.75 18.41 12.41 18.75 12 18.75C11.59 18.75 11.25 18.41 11.25 18V12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H11.25V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z" fill="#64748B"/>
</svg>
<p className="addQuestion">Add new Question</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="col-12">
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined />}>
                            Upload Video
                          </Button>
                        </Upload>
                      </div>
                    </div>
                  </div>
                </div>

                <DialogActions>
                  <button className="cancel_btn" onClick={addNewSchoolClose}>
                    Cancel
                  </button>
                  <button className="add_btn" onClick={addedSchoolMessage}>
                    Add
                  </button>
                </DialogActions>
              </Dialog>
              <Dialog
                className="center_popup"
                open={openVideoInformation}
                onClose={setopenVideoInformationClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              > 
                <div className="container-fluid">
                  <div className="row align-items-center">
                     
                      <div className="d-flex w-100 justify-content-between align-items-center p-0">
                      <h1 className="modal_title ">Video Information</h1>
                      <button
                          className="btn-none p-0"
                          type="button"
                          onClick={setopenVideoInformationClose}
                        >
                          <img src={closeBtnIcon} alt="closeBtnIcon" />
                        </button>
                      </div>
                    
                  </div>
                  <div className="d-flex align-items-center mt-3">
                            <p className="videoInfoTitle">Uploaded Date & Time</p>
                            <p className="videoInfoSub">01/29/2024 06:44 PM</p>
                  </div>
                  <div className="d-flex align-items-center">
                            <p className="videoInfoTitle">Approved Date & Time</p>
                            <p className="videoInfoSub">01/29/2024 08:21 PM</p>
                  </div>
                  <div className="d-flex align-items-center">
                            <p className="videoInfoTitle">Uploaded by</p>
                            <p className="videoInfoSub"><img className="videoInfoProfilePic" src={videoInfoProfilePic} alt="" /> Hannah Joel</p>
                  </div>
                  <div className="d-flex align-items-center">
                            <p className="videoInfoTitle">Modified</p>
                            <p className="videoInfoSub">NA</p>
                  </div>
                   
                </div>

                <DialogActions>
                  <button className="cancel_btn" onClick={setopenVideoInformationClose}>
                    Close
                  </button>
                  {/* <button className="add_btn" onClick={addedSchoolMessage}>
                    Add
                  </button> */}
                </DialogActions>
              </Dialog>
              <Modal
                            title="Delete video"
                            centered
                            open={deleteSchoolModalOpen}
                            onOk={removeSchool}
                            onCancel={() => deleteSchoolOpen(false)} 
                            okButtonProps={{ style: { backgroundColor: '#FB434C', borderColor: '#FB434C', color: '#fff' } }}
                            okText="Delete" 
                        >{contextHolder}
                            <p>Are you sure you want to delete this video?</p>
                            <div className="d-flex justify-content-between videoListContainer pt-0">
      <div className="d-flex gap-2">
        <div className="videoTempContainer">
          <img className="videoTempImg" src={videoTemp} alt="videoTemp" />

          <svg
            className="playIcon"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#222222" fillOpacity="0.42" />
            <path
              d="M18.123 12.0003C18.1234 12.1488 18.0853 12.2949 18.0125 12.4244C17.9397 12.5539 17.8346 12.6623 17.7074 12.7391L9.82805 17.5593C9.6952 17.6406 9.54305 17.685 9.38731 17.6879C9.23156 17.6908 9.07787 17.652 8.94211 17.5757C8.80764 17.5005 8.69562 17.3908 8.61757 17.258C8.53953 17.1252 8.49827 16.9739 8.49805 16.8199V7.18066C8.49827 7.0266 8.53953 6.87538 8.61757 6.74255C8.69562 6.60971 8.80764 6.50007 8.94211 6.42488C9.07787 6.34851 9.23156 6.30977 9.38731 6.31265C9.54305 6.31553 9.6952 6.35994 9.82805 6.44129L17.7074 11.2614C17.8346 11.3383 17.9397 11.4467 18.0125 11.5761C18.0853 11.7056 18.1234 11.8517 18.123 12.0003Z"
              fill="white"
            />
          </svg>

          <p className="duration">5:12</p>
        </div>
        <div>
          <h3 className="videoTitle">Tadasana</h3>
          <p className="videoSub">This condition is very useful for beginners who wish to co..</p>
          <div className="d-flex flex-wrap gap-2">
            <p className="conditionBullet">
              <svg
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2.5" cy="2.5" r="2.5" fill="#1E293B" />
              </svg>
              Beginner
            </p>
            <p className="conditionBullet">
              <svg
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2.5" cy="2.5" r="2.5" fill="#1E293B" />
              </svg>
              Musculoskeletal condition
            </p>
          </div>
        </div>
      </div>
       
    </div>
                        </Modal>
            </div>
          </div>
          <div className="videoListMainContainer">
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
            {videoList(
              "05:12",
              "Hatha yoga Relaxation",
              "This condition is very useful for beginners who..",
              " Beginner",
              "Bhanunath, Gokul",
              "12/05/2024 08:02 AM"
            )}
          </div>
          <div className="paginationContainer">
            <Pagination className="pagination" defaultCurrent={6} total={500} />
            ;
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLibrary;
