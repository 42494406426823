import LeftNavigationBar from '../../shareableResources/LeftNavigationBar'
import './index.css'
import plusIcon from '../../../assets/images/rightContainer/plusIcon.svg'
import communityIcon from '../../../assets/images/rightContainer/communityIcon.svg'
import closeBtnIcon from '../../../assets/images/rightContainer/closeBtnIcon.svg'
import noCommunityCardIcon from '../../../assets/images/rightContainer/noCommunityCardIcon.svg'
import StickyLabel from '../../shareableResources/StickyLabel'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';  
import RightTopHeaderMenu from '../../shareableResources/RightTopHeaderMenu'  
import { Input, message, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react"; 
import videoTemp from "../../../assets/images/rightContainer/videoTemp.svg";
import instructorProfilePic from "../../../assets/images/rightContainer/instructorProfilePic.svg";   
import Menu from "@mui/material/Menu"; 
import MenuItem from "@mui/material/MenuItem"; 
import { updateJwtToken, updateSelectedMenu } from '../../../globalDataManager/UserDetails/userDetails'
import { useDispatch } from 'react-redux'
const WellnessProgram = () => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch()
    const [openEditSchool, setOpenEditSchool] = useState(false);
    const [openVideoInformation, setopenVideoInformation] = useState(false); 
    const [deleteSchoolModalOpen, deleteSchoolOpen] = useState(false);
    const removeSchool = () => {
      messageApi
        .open({
          type: "loading",
          content: "Deleting..",
          duration: 1.5,
        })
        .then(() =>
          message.success(
            "Video been deleted successfully.",
            4.5,
            deleteSchoolOpen(false)
          )
        );
      // .then(() => message.info('Community Added Successfully', 4.5));
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMore = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
   
   
  
  const editSchoolOpen = (scrollType) => () => {
    setOpenEditSchool(true);
    setScroll(scrollType);
  };

  const editSchoolClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenEditSchool(false);
    }
  };
  const setopenVideoInformationOpen = (scrollType) => () => {
    setopenVideoInformation(true);
    setScroll(scrollType);
  };

  

    const videoList = () => (
        <div className="d-flex justify-content-between videoListContainer">
          <div className="d-flex gap-2">
            <div className="videoTempContainer">
              <img className="videoTempImg" src={videoTemp} alt="videoTemp" />
    
              <svg
                className="playIcon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12" cy="12" r="12" fill="#222222" fillOpacity="0.42" />
                <path
                  d="M18.123 12.0003C18.1234 12.1488 18.0853 12.2949 18.0125 12.4244C17.9397 12.5539 17.8346 12.6623 17.7074 12.7391L9.82805 17.5593C9.6952 17.6406 9.54305 17.685 9.38731 17.6879C9.23156 17.6908 9.07787 17.652 8.94211 17.5757C8.80764 17.5005 8.69562 17.3908 8.61757 17.258C8.53953 17.1252 8.49827 16.9739 8.49805 16.8199V7.18066C8.49827 7.0266 8.53953 6.87538 8.61757 6.74255C8.69562 6.60971 8.80764 6.50007 8.94211 6.42488C9.07787 6.34851 9.23156 6.30977 9.38731 6.31265C9.54305 6.31553 9.6952 6.35994 9.82805 6.44129L17.7074 11.2614C17.8346 11.3383 17.9397 11.4467 18.0125 11.5761C18.0853 11.7056 18.1234 11.8517 18.123 12.0003Z"
                  fill="white"
                />
              </svg>
    
              <p className="duration">10:00</p>
            </div>
            <div>
              <h3 className="videoTitle">Sleep disorder</h3>
              <p className="videoSub">This condition is very useful for beginners who..</p>
              <div className="d-flex flex-wrap gap-2">
                <p className="conditionBullet">
                  <svg
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="2.5" cy="2.5" r="2.5" fill="#1E293B" />
                  </svg>
                  Beginner
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 alig-items-center ms-5">
              {/* <img
                className="instructorProfilePic"
                src={instructorProfilePic}
                alt="instructorProfilePic"
              /> */}
              <div>
                <h5 className="instrucorName">Last updated</h5>
                <p className="uploadData">Aug 17, 2024 10:01 am</p>
              </div>
            </div>
          <div className="instructorContainer">
            
            <button
              className="btn-none ms-5"
              id="basic-button"
              aria-controls={openMore ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMore ? "true" : undefined}
              onClick={handleClick}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33594 15.8333C8.33594 16.75 9.08594 17.5 10.0026 17.5C10.9193 17.5 11.6693 16.75 11.6693 15.8333C11.6693 14.9167 10.9193 14.1667 10.0026 14.1667C9.08594 14.1667 8.33594 14.9167 8.33594 15.8333Z"
                  fill="#1E293B"
                  stroke="#1E293B"
                  strokeWidth="0.25"
                />
                <path
                  d="M8.33594 4.16536C8.33594 5.08203 9.08594 5.83203 10.0026 5.83203C10.9193 5.83203 11.6693 5.08203 11.6693 4.16536C11.6693 3.2487 10.9193 2.4987 10.0026 2.4987C9.08594 2.4987 8.33594 3.2487 8.33594 4.16536Z"
                  fill="#1E293B"
                  stroke="#1E293B"
                  strokeWidth="0.25"
                />
                <path
                  d="M8.33594 10.0013C8.33594 10.918 9.08594 11.668 10.0026 11.668C10.9193 11.668 11.6693 10.918 11.6693 10.0013C11.6693 9.08464 10.9193 8.33464 10.0026 8.33464C9.08594 8.33464 8.33594 9.08464 8.33594 10.0013Z"
                  fill="#1E293B"
                  stroke="#1E293B"
                  strokeWidth="0.25"
                />
              </svg>
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMore}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={setopenVideoInformationOpen("paper")}>
                <svg
                  className="me-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1775_19026)">
                    <path
                      d="M7.9987 14.6673C11.6806 14.6673 14.6654 11.6825 14.6654 8.00065C14.6654 4.31875 11.6806 1.33398 7.9987 1.33398C4.3168 1.33398 1.33203 4.31875 1.33203 8.00065C1.33203 11.6825 4.3168 14.6673 7.9987 14.6673Z"
                      stroke="#475569"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 10.6667V8"
                      stroke="#475569"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 5.33398H8.00667"
                      stroke="#475569"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1775_19026">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>{" "}
                View Info
              </MenuItem>
              <MenuItem onClick={editSchoolOpen("paper")}>
                <svg
                  className="me-3"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.79313 14.0005H3.00001C2.73479 14.0005 2.48043 13.8951 2.2929 13.7076C2.10536 13.52 2.00001 13.2657 2.00001 13.0005V10.2073C1.99959 10.076 2.02528 9.94584 2.0756 9.82448C2.12593 9.70313 2.19987 9.593 2.29313 9.50047L5.79313 14.0005ZM5.79313 14.0005C5.9245 14.0009 6.05464 13.9752 6.17599 13.9249M5.79313 14.0005L6.17599 13.9249M6.17599 13.9249C6.29735 13.8746 6.40748 13.8006 6.50001 13.7073L6.17599 13.9249ZM14.1848 4.60819L14.2069 4.5861L14.1848 4.6082C14.2748 4.69816 14.3461 4.80496 14.3948 4.92251C14.4435 5.04006 14.4686 5.16605 14.4686 5.29329C14.4686 5.42052 14.4435 5.54651 14.3948 5.66406C14.3461 5.78161 14.2748 5.88841 14.1848 5.97837L14.2069 6.00047L14.1848 5.97838L6.47791 13.6853L6.47782 13.6853C6.38822 13.7757 6.28155 13.8473 6.16402 13.896C6.0465 13.9447 5.92046 13.9696 5.79323 13.9692H5.79313H3.00001C2.74308 13.9692 2.49667 13.8672 2.315 13.6855C2.13332 13.5038 2.03126 13.2574 2.03126 13.0005L2.03126 10.2073L2.03125 10.2072C2.03085 10.08 2.05574 9.95398 2.10447 9.83646C2.15321 9.71893 2.22482 9.61226 2.31514 9.52266L2.31523 9.52257L10.0221 1.81507L10 1.79297L10.0221 1.81507C10.1121 1.72509 10.2189 1.65371 10.3364 1.60501C10.454 1.55631 10.58 1.53125 10.7072 1.53125C10.8344 1.53125 10.9604 1.55631 11.078 1.60501C11.1955 1.65371 11.3023 1.72509 11.3923 1.81507L14.1848 4.60819ZM5.79313 13.0317H5.80607L5.81523 13.0226L11.3152 7.52257L11.3373 7.50047L11.3152 7.47838L8.5221 4.68525L8.50001 4.66315L8.47791 4.68525L2.97791 10.1853L2.96876 10.1944V10.2073V13.0005V13.0317H3.00001H5.79313ZM11.9779 6.81507L12 6.83717L12.0221 6.81507L13.5221 5.31507L13.5442 5.29297L13.5221 5.27087L10.729 2.47837L10.7069 2.45628L10.6848 2.47838L9.18478 3.97838L9.16268 4.00048L9.18479 4.02257L11.9779 6.81507Z"
                    fill="#475569"
                    stroke="#475569"
                    strokeWidth="0.0625"
                  />
                </svg>
                Edit Video
              </MenuItem>
              <MenuItem onClick={() => deleteSchoolOpen(true)}>
                <svg
                  className="me-3"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 3H11.25V2.25C11.25 1.78587 11.0656 1.34075 10.7374 1.01256C10.4092 0.684374 9.96413 0.5 9.5 0.5H6.5C6.03587 0.5 5.59075 0.684374 5.26256 1.01256C4.93437 1.34075 4.75 1.78587 4.75 2.25V3H2.5C2.30109 3 2.11032 3.07902 1.96967 3.21967C1.82902 3.36032 1.75 3.55109 1.75 3.75C1.75 3.94891 1.82902 4.13968 1.96967 4.28033C2.11032 4.42098 2.30109 4.5 2.5 4.5H2.75V13C2.75 13.3315 2.8817 13.6495 3.11612 13.8839C3.35054 14.1183 3.66848 14.25 4 14.25H12C12.3315 14.25 12.6495 14.1183 12.8839 13.8839C13.1183 13.6495 13.25 13.3315 13.25 13V4.5H13.5C13.6989 4.5 13.8897 4.42098 14.0303 4.28033C14.171 4.13968 14.25 3.94891 14.25 3.75C14.25 3.55109 14.171 3.36032 14.0303 3.21967C13.8897 3.07902 13.6989 3 13.5 3ZM6.25 2.25C6.25 2.1837 6.27634 2.12011 6.32322 2.07322C6.37011 2.02634 6.4337 2 6.5 2H9.5C9.5663 2 9.62989 2.02634 9.67678 2.07322C9.72366 2.12011 9.75 2.1837 9.75 2.25V3H6.25V2.25ZM11.75 12.75H4.25V4.5H11.75V12.75ZM7.25 6.5V10.5C7.25 10.6989 7.17098 10.8897 7.03033 11.0303C6.88968 11.171 6.69891 11.25 6.5 11.25C6.30109 11.25 6.11032 11.171 5.96967 11.0303C5.82902 10.8897 5.75 10.6989 5.75 10.5V6.5C5.75 6.30109 5.82902 6.11032 5.96967 5.96967C6.11032 5.82902 6.30109 5.75 6.5 5.75C6.69891 5.75 6.88968 5.82902 7.03033 5.96967C7.17098 6.11032 7.25 6.30109 7.25 6.5ZM10.25 6.5V10.5C10.25 10.6989 10.171 10.8897 10.0303 11.0303C9.88968 11.171 9.69891 11.25 9.5 11.25C9.30109 11.25 9.11032 11.171 8.96967 11.0303C8.82902 10.8897 8.75 10.6989 8.75 10.5V6.5C8.75 6.30109 8.82902 6.11032 8.96967 5.96967C9.11032 5.82902 9.30109 5.75 9.5 5.75C9.69891 5.75 9.88968 5.82902 10.0303 5.96967C10.171 6.11032 10.25 6.30109 10.25 6.5Z"
                    fill="#475569"
                  />
                </svg>
                Delete Video
              </MenuItem>
            </Menu>
          </div>
        </div>
      );
      const props = {
        name: 'file',
        accept: 'image/*', // Ensures only image formats are accepted
        beforeUpload: (file) => {
          // Simulate processing the file locally
          console.log('File before upload:', file);
      
          // Simulate a successful upload with a timeout
          setTimeout(() => {
            message.success(`${file.name} file uploaded successfully`);
          }, 1000);
      
          // Return false to prevent the default upload action
          return false;
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log('Uploading:', info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            console.log('Response:', info.file.response);
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            console.log('Error:', info.file.error);
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onError(err) {
          console.error('Upload error:', err);
        }
      };
    const [open, setOpen] = useState(false);
    const [openAddCommunity, setopenAddCommunity] = useState(false);
    const [scroll, setScroll] = useState('paper');

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    
    const handleCloseAddCommunity = (event, reason) => {
        if (reason !== 'backdropClick') {
            setopenAddCommunity(false);
        }


    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
      dispatch(updateJwtToken()); 
      dispatch(updateSelectedMenu("wellnessprogram")); 

        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const addedCommunityMessage = () => {
        messageApi
            .open({
                type: 'loading',
                content: 'Adding new Community',
                duration: 1.5,
            })
            .then(() => message.success('Community Added Successfully', 1.5, setopenAddCommunity(false)))
        // .then(() => message.info('Community Added Successfully', 2.5));
    };
    const [isSchoolCard, isSchoolCardActive] = useState(true);

    const emptySchoolCard = () => (
        <div className='emptySchoolCard'>
            <img src={noCommunityCardIcon} alt='noCommunityCardIcon' />
            <p className='noCommunityText'>No Community added yet</p>
        </div>
    )
    const handleCloseAddInstructor = (event, reason) => {
        if (reason !== "backdropClick") {
          setopenAddCommunity(false);
        }
      };

    const addedInstructorMessage = () => {
        messageApi
          .open({
            type: "loading",
            content: "Adding new Instructor",
            duration: 1.5,
          })
          .then(() =>
            message.success(
              "Instructor Added Successfully",
              1.5,
              setopenAddCommunity(false)
            )
          );
        // .then(() => message.info('Community Added Successfully', 2.5));
      };
    const basicInformation = () => (
        <div className="row row-gap-3 modal_container">
            <h3 className='infoTitle'>Basic Information</h3>
          <div className="col-md-4">
            <label className="modal_label">
              First name <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
              Middle name  
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
              Last name <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Date of Birth <span className="text-danger">*</span>
            </label>
            <Input type='date' className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Gender <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Sexual orientation <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Marital Status <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Race <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            SSN <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <h3 className='infoTitle   '>Contact information</h3>

          <div className="col-md-4">
            <label className="modal_label">
            Home telephone <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Home telephone <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Mobile <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Email <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Other telephone <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <h3 className='infoTitle   '>Address details</h3>
          <div className="col-md-6">
            <label className="modal_label">
            Address line 1 <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-6">
            <label className="modal_label">
            Address line 2 
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            County
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            City
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            State
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Country
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            Zip
            </label>
            <Input className="border-input" />
          </div>
         
          
        </div>
      );
    
      const documents = () => (
        <div className="row row-gap-3   modal_container">
            <div className="col-md-4">
            <label className="modal_label">
            Insurance type <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Insurance category <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Insurance company <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Relationship to patient <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            SSN <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Last Name 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Fist name 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Middle name 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Sex 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Date of birth 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Middle name 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Sex 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Subscribed insured 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Patient insured 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Copay($) 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            policy/FECA 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Group policy # 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Group policy # 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Plan /Program name 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Billing method 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Effective date 
            </label>
            <Input className="border-input" />
          </div>
            <div className="col-md-4">
            <label className="modal_label">
            Termination date 
            </label>
            <Input className="border-input" />
          </div> 
             <h2 className="infoTitle mb-2">Address details</h2>
                   
             <div className="col-md-6">
            <label className="modal_label">
            Address line 1 <span className="text-danger">*</span>
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-6">
            <label className="modal_label">
            Address line 2 
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            County 
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            City 
            </label>
            <Input className="border-input" />
          </div>
          <div className="col-md-4">
            <label className="modal_label">
            State 
            </label>
            <Input className="border-input" />
          </div>
        </div>
      )

    const onChange = (key) => {
        console.log(key);
      };
      const items = [
        {
          key: "1",
          label: "Demographics",
          children: basicInformation(),
        },
        {
          key: "2",
          label: "Insurance details",
          children: documents(),
        },
      ];
    

    return (
        <div className='d-flex'>
            <LeftNavigationBar />
            <div className='rightDashboard_container'>
            <RightTopHeaderMenu title='Wellness Program' />
                {/* <div className='d-flex justify-content-end align-items-center head_sticky myPatientSubHeader'>
                     
                    <div className='myPatientSubHeaderBtn'><button className='blue_btn' onClick={() => setopenAddCommunity(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M3.99995 8H12M8.00478 12L7.99512 4" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>Add patient</button></div>
                </div> */}
                <StickyLabel />
                <div className='schoolsContainer'>

                    {isSchoolCard ? (videoList()) : (emptySchoolCard())}

                </div>
                <Dialog
          className="right_popup"
          open={openAddCommunity}
          onClose={handleCloseAddInstructor}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <div>
            <div className="d-flex w-100 align-items-center justify-content-between modal_sticky_top">
              <h1 className="modal_title">Add Patient</h1>
              <button
                className="btn-none"
                type="button"
                onClick={handleCloseAddInstructor}
              >
                <img src={closeBtnIcon} alt="closeBtnIcon" />
              </button>
            </div>
            {contextHolder}

            <div className="container-fluid">
              <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
          </div>

          <DialogActions>
            <button className="cancel_btn" onClick={handleCloseAddInstructor}>
              Cancel
            </button>
            <button className="add_btn" onClick={addedInstructorMessage}>
              Add
            </button>
          </DialogActions>
        </Dialog>
            </div>
        </div>
    )
}

export default WellnessProgram