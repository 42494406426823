import LeftNavigationBar from "../../shareableResources/LeftNavigationBar";
import "./index.css";
import backIcon from "../../../assets/images/rightContainer/backIcon.svg";
import plusIcon from "../../../assets/images/rightContainer/plusIcon.svg";
import editSchoolIcon from "../../../assets/images/rightContainer/editSchoolIcon.svg";
import removeSchoolIcon from "../../../assets/images/rightContainer/removeSchoolIcon.svg";
import closeBtnIcon from "../../../assets/images/rightContainer/closeBtnIcon.svg";
import { useNavigate } from "react-router-dom";
import StickyLabel from "../../shareableResources/StickyLabel";
import noSchoolListIcon from "../../../assets/images/rightContainer/noSchoolListIcon.svg"; 
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Input, message, Modal, Tabs } from "antd";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useRef, useState } from "react";
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';

const Reports = () => {
  const navigate = useNavigate();
  const [openEditCommunity, setopenEditCommunity] = useState(false);
  const handleCloseAddInstructor = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
    }
  };
  const addedCommunityMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing Community",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Community Edited Successfully",
          1.5,
          setopenEditCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const props = {
    name: 'file',
    accept: 'image/*', // Ensures only image formats are accepted
    beforeUpload: (file) => {
      // Simulate processing the file locally
      console.log('File before upload:', file);
  
      // Simulate a successful upload with a timeout
      setTimeout(() => {
        message.success(`${file.name} file uploaded successfully`);
      }, 1000);
  
      // Return false to prevent the default upload action
      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log('Uploading:', info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log('Response:', info.file.response);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        console.log('Error:', info.file.error);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onError(err) {
      console.error('Upload error:', err);
    }
  };
  const addedInstructorMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing new Instructor",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Instructor Edited Successfully",
          1.5,
          setopenAddCommunity(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const [age, setAge] = useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const basicInformation = () => (
    <div className="row row-gap-3 mt-3">
      <div className="col-md-6">
        <label className="modal_label">
          First name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Last name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Email address <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Mobile <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Country</label>
        <br />
        <Select
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">State</label>
        <br />
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">City</label>
        <br />
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value=""></MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </div>
      <div className="col-md-6">
        <label className="modal_label">
          Zip code <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
     
              <div className="col-12">
              <h2 className="instructorSubTitle mb-2">Social links</h2>
              <hr className="mt-0" />
              <div className="d-flex align-items-center w-100">
                <p className="socialLink">Linkedin</p>
                <Input className="border-input" placeholder="paste your link here" />

              </div>
              <div className="d-flex align-items-center w-100 mt-3">
                <p className="socialLink">Facebook</p>
                <Input className="border-input" placeholder="paste your link here" />

              </div>
              <div className="d-flex align-items-center w-100 mt-3">
                <p className="socialLink">Instagram</p>
                <Input className="border-input" placeholder="paste your link here" />

              </div>
              <div className="d-flex align-items-center w-100 mt-3">
                <p className="socialLink">Website</p>
                <Input className="border-input" placeholder="paste your link here" />

              </div>
              </div>
    </div>
  );

  const documentsList = () => (
    <div className="row row-gap-3 mt-3">
         <div className="col12">
         <h2 className="instructorSubTitle mb-2">Social links</h2>
              <hr className="mt-0" />
                <Upload {...props}>
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload>
         </div>
    </div>
  )

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Basic information",
      children: basicInformation(),
    },
    {
      key: "2",
      label: "Documents",
      children: documentsList(),
    },
  ];
  const reportsList = (reports) => (
    <div className="instructorList">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="5.95745" fill="#DFF5E6"/>
<path d="M29 26.75H28.25V11.75C28.25 11.5511 28.171 11.3603 28.0303 11.2197C27.8897 11.079 27.6989 11 27.5 11H22.25C22.0511 11 21.8603 11.079 21.7197 11.2197C21.579 11.3603 21.5 11.5511 21.5 11.75V15.5H17C16.8011 15.5 16.6103 15.579 16.4697 15.7197C16.329 15.8603 16.25 16.0511 16.25 16.25V20H12.5C12.3011 20 12.1103 20.079 11.9697 20.2197C11.829 20.3603 11.75 20.5511 11.75 20.75V26.75H11C10.8011 26.75 10.6103 26.829 10.4697 26.9697C10.329 27.1103 10.25 27.3011 10.25 27.5C10.25 27.6989 10.329 27.8897 10.4697 28.0303C10.6103 28.171 10.8011 28.25 11 28.25H29C29.1989 28.25 29.3897 28.171 29.5303 28.0303C29.671 27.8897 29.75 27.6989 29.75 27.5C29.75 27.3011 29.671 27.1103 29.5303 26.9697C29.3897 26.829 29.1989 26.75 29 26.75ZM23 12.5H26.75V26.75H23V12.5ZM17.75 17H21.5V26.75H17.75V17ZM13.25 21.5H16.25V26.75H13.25V21.5Z" fill="#176D6F"/>
</svg>
 
        <h2 className="instructorHead">{reports}</h2>
         
    </div>
  );

  const listOfSchools = (title, sub) => (
    <div className="d-flex">
      <p className="schoolList_sub schoolList_sub_width ">{title}</p>
      <p className="schoolList_title">{sub}</p>
    </div>
  );
  const [open, setOpen] = useState(false);
  const [openEditSchool, setOpenEditSchool] = useState(false);
  const [openAddCommunity, setopenAddCommunity] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const addNewSchoolOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const addNewSchoolClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const editSchoolOpen = (scrollType) => () => {
    setOpenEditSchool(true);
    setScroll(scrollType);
  };

  const editSchoolClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenEditSchool(false);
    }
  };
  const handleCloseAddCommunity = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddCommunity(false);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]); 



  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const addedSchoolMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new School",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "School has been added successfully.",
          4.5,
          setOpen(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };
  const editeddSchoolMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Editing School Details",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "School has been edited successfully.",
          4.5,
          setOpenEditSchool(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };

  const [deleteSchoolModalOpen, deleteSchoolOpen] = useState(false);
  const removeSchool = () => {
    messageApi
      .open({
        type: "loading",
        content: "Deleting..",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "School has been deleted successfully.",
          4.5,
          deleteSchoolOpen(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 4.5));
  };

  const [isSchoolCard, isSchoolCardActive] = useState(true);

  const emptyInstructorCard = () => (
    <div className="emptySchoolCard">
     <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="5.95745" fill="#DFF5E6"/>
<path d="M29 26.75H28.25V11.75C28.25 11.5511 28.171 11.3603 28.0303 11.2197C27.8897 11.079 27.6989 11 27.5 11H22.25C22.0511 11 21.8603 11.079 21.7197 11.2197C21.579 11.3603 21.5 11.5511 21.5 11.75V15.5H17C16.8011 15.5 16.6103 15.579 16.4697 15.7197C16.329 15.8603 16.25 16.0511 16.25 16.25V20H12.5C12.3011 20 12.1103 20.079 11.9697 20.2197C11.829 20.3603 11.75 20.5511 11.75 20.75V26.75H11C10.8011 26.75 10.6103 26.829 10.4697 26.9697C10.329 27.1103 10.25 27.3011 10.25 27.5C10.25 27.6989 10.329 27.8897 10.4697 28.0303C10.6103 28.171 10.8011 28.25 11 28.25H29C29.1989 28.25 29.3897 28.171 29.5303 28.0303C29.671 27.8897 29.75 27.6989 29.75 27.5C29.75 27.3011 29.671 27.1103 29.5303 26.9697C29.3897 26.829 29.1989 26.75 29 26.75ZM23 12.5H26.75V26.75H23V12.5ZM17.75 17H21.5V26.75H17.75V17ZM13.25 21.5H16.25V26.75H13.25V21.5Z" fill="#176D6F"/>
</svg>


      <p className="noCommunityText">No Report list</p>
    </div>
  );

  
  const onFilterChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
  }; 
  const options = [
    {
      label: 'Musculoskeletal condition',
      value: 'Musculoskeletal condition',
    },
    {
      label: 'Emotional & Mental Health',
      value: 'Emotional & Mental Health',
    },
    {
      label: 'Sleep Disorders',
      value: 'Sleep Disorders',
    },
    {
      label: 'Over weight / Obesity',
      value: 'Over weight / Obesity',
    },
    {
      label: 'Diabetes / Metabolic Syndromes',
      value: 'Diabetes / Metabolic Syndromes',
    },
  ];


  return (
    <div className="d-flex">
      <LeftNavigationBar />
      <div className="rightCommunityList_container">
        <div className="schoolList_container">
          <div className="schoolListTitle_Contianer">
            {/* <button
              className="btn-none"
              type="button"
              onClick={() => navigate("/instructor")}
            >
              <img src={backIcon} alt="backIcon" />
            </button> */}
            <div>
              <h2 className="schoolListContianer_title">Reports</h2>
              {/* <p className='viewProfile' onClick={()=> navigate('/view-school-profile')}>View profile</p> */}
            </div>
          </div>
          {isSchoolCard ? reportsList("Age & Gender") : emptyInstructorCard()}
          <StickyLabel />
        </div>
        <div className="schoolDetails_container">
          <div className="reportsHeader_container">
            <div>
                <h1 className="reportTitle">Age & Gender</h1>
                <p className="reportSub">View the age & gender reports of all users</p>
            </div>
            <button className="btn_dark" type="button">Generate Report</button>

          </div>
          <div className="healthConditionFilterContainer">
          <h2 className="healthConditionTitle">Health Condition</h2>
            <div className="d-flex flex-wrap">
           
 
   
    <Checkbox.Group options={options} onChange={onFilterChange} />
    

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
