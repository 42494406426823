import {store} from "../../store/store"

// const rootDomain = "https://kalariharmonyadmindev.bmsmartware.com/api/";
// const rootDomain = "https://localhost:7277/";
// const rootDomain = "https://friska.bmsmartware.com/api/";
// const rootDomain = "https://friskatest.bmsmartware.com/api/";
const rootDomain = "https://uat.friska.ai/api/";
// const rootDomain = "https://webapp.friska.ai/api/";


const getAuthToken = () => {
  const state = store.getState();
  return state.userDetails.jwtToken;
};

const headers = {
  "Content-Type": "application/json",
  offset: new Date().getTimezoneOffset().toString(),
  
};


const headersWithToken = (token) => ({
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  offset: new Date().getTimezoneOffset().toString(),
  // Authorization: `Bearer ${token.replace(/^"(.*)"$/, '$1')}`,
  Authorization: `Bearer ${token}`,
  DataBaseName: "Friska_Database"
});

/* 
! signIn POST API
*/

export const postUserNameAndPassword = async (content) => {
  const POST_API = `${rootDomain}User/Authenticate`;

  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
    headers: headers,
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } 
  } catch (e) {
    console.log(e.message);
  }
};

/* 
! Dashboard card Count GET API
*/

export const getDashboardCardCountService = async (navigate) => {
  const GET_API = `${rootDomain}User/GetDashboardData`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 



/* 
! Add Patient Details POST API
*/

export const postAddPatientDetailsService = async (navigate, content) => {
  const POST_API = `${rootDomain}Provider/AddUpdatePatient`; 
  const authToken = await getAuthToken();
  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
    headers: headersWithToken(authToken),
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "postttt")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};
 
/* 
! Add Patient Insurance Details POST API
*/

export const postAddPatientInsuranceDetailsService = async (navigate, content) => {
  const POST_API = `${rootDomain}Provider/AddUpdatePatientInsurance`; 
  const authToken = await getAuthToken();
  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
    headers: headersWithToken(authToken),
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "postttt")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};
 



/* 
! All Gender GET API
*/

export const getAllGenderService = async (navigate) => {
  const GET_API = `${rootDomain}Master/GetAllGender`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET My Patienta API
*/

export const getMyPatientsService = async (name, navigate) => {
  const GET_API = `${rootDomain}Provider/GetAllPatients?searchKey=${name}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 



/* 
! GET My Patients chart API
*/

export const getPatientChartService = async (id,navigate) => {
  const GET_API = `${rootDomain}Provider/GetPatientByPatientId?patientId=${id}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET My Patient Summary API
*/

export const getPatientSummaryService = async (id,date, navigate) => {
  // const GET_API = `${rootDomain}Provider/GetPatientByPatientId?patientId=${id}`;
  const GET_API = `${rootDomain}Provider/GetPatientSummaryById?memberId=${id}&date=${date}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET My Patient Water API
*/

export const getPatientWaterService = async (id,date, navigate) => { 
  const GET_API = `${rootDomain}Provider/GetPatientWaterRecord?memberId=${id}&date=${date}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET My Patient Nutrition API
*/

export const getPatientNutritionService = async (id,date, navigate) => { 
  const GET_API = `${rootDomain}Provider/GetCalculatedValues?memberId=${id}&date=${date}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET Change Password API
*/

export const getChangePasswordService = async (currentPassword,newPassword, navigate) => { 
  const GET_API = `${rootDomain}User/ChangePassword?currentPassword=${currentPassword}&newPassword=${newPassword}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET My Patient Vitals API
*/

export const getPatientVitalsService = async (id,date, navigate) => { 
  const GET_API = `${rootDomain}Provider/GetPatientVitalsbyId?memberId=${id}&date=${date}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 

/* 
! GET All Medical Condition API
*/

export const getAllMedicalConditionService = async (navigate) => {
  const GET_API = `${rootDomain}Master/GetAllHealthConditions`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! Add Cheif Complaint POST API
*/

export const postAddCheifComplaintService = async (navigate, content) => {
  const POST_API = `${rootDomain}Provider/AddOrUpdatePatientChiefComplaint`;
  const authToken = await getAuthToken();
  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
     headers: headersWithToken(authToken),
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
     
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};




/* 
! GET Cheif Complaint API
*/

export const getCheifComplaintService = async (id,navigate) => {
  const GET_API = `${rootDomain}Provider/GetPatientChiefComplaint?patientId=${id}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET Account user details API
*/

export const getAccountUserDetails = async (navigate) => {
  const GET_API = `${rootDomain}User/GetUserDetails`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET Account user details API
*/

export const getAllPatientInPagination = async (navigate, number, size) => {
  const GET_API = `${rootDomain}User/GetAllPatientsPagination?pageNumber=${number}&pageSize=${size}`;
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 



/* 
! GET forgot password  API
*/

export const getForgotPassword = async (email, navigate) => {
  const GET_API = `${rootDomain}User/SendMailforResetPassword?email=${email}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 





/* 
! GET OTP for forgot password API
*/

export const getOTPforForgotPassword = async (email,otp, navigate) => {
  const GET_API = `${rootDomain}User/Checkotp?email=${email}&otp=${otp}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET Change password for forgot password API
*/

export const getChangeForgotPassword = async (email,password, navigate) => {
  const GET_API = `${rootDomain}User/ChangePasswordbyEmail?email=${email}&password=${password}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET Send invatation link for patient API
*/

export const getInvitationLink = async (id, navigate) => {
  const GET_API = `${rootDomain}Provider/SendInvitation?memberId=${id}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 




/* 
! GET Change password for user API
*/

export const getChangePasswordForUserService = async (name, navigate) => {
  const GET_API = `${rootDomain}User/ChangeUserPassword?newPassword=${name}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! Oragnisation Details POST API
*/

export const postAddUpdateFacilityService = async (navigate, content) => {
  const POST_API = `${rootDomain}User/AddUpdateFacility`;
  const authToken = await getAuthToken();
  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
     headers: headersWithToken(authToken),
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
     
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};


/* 
! add update employee POST API
*/

export const postAddUpdateemployeeService = async (navigate, content) => {
  const POST_API = `${rootDomain}User/AddUpdateEmployee`;
  const authToken = await getAuthToken();
  
  console.log(POST_API, "api");
  const options = { 
    method: "POST",
     headers: headersWithToken(authToken),
    body: JSON.stringify(content),
  };

  try {
    const response = await fetch(POST_API, options);
    console.log(response);

    if (response.status === 200) {
      const data = await response.json();
     
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
};






/* 
! GET users List  API
*/

export const getUsersListService = async (name,navigate) => {
  const GET_API = `${rootDomain}User/GetAllUsers?searchKey=${name}`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 


/* 
! GET role List  API
*/

export const getRoleTyeListService = async (navigate) => {
  const GET_API = `${rootDomain}master/GetAllRoles`; 
  const authToken = await getAuthToken();
  const options = {
    method: "GET",
    headers: headersWithToken(authToken),
  };
  console.log("options", options);

  try {
    const response = await fetch(GET_API, options);
    console.log(response, "dash")
    if (response.status === 200) {
      const data = await response.json();
      console.log(data, "aaaa")
      return data;
    } else if (response.status === 401) {
      localStorage.removeItem("Friska_Database")
      navigate("/sign-in")
      return "invalid";
    }
  } catch (e) {
    console.log(e.message);
  }
}; 





/* 
! Medical Condition listing API
*/

// export const getMedicalConditionService = async (navigate) => {
//   const GET_API = `${rootDomain}Dashboard/getMedicalconditionmodelsCount`;
//   const authToken = await getAuthToken();
//   const options = {
//     method: "GET",
//     headers: headersWithToken(authToken),
//   };
//   console.log("options", options);

//   try {
//     const response = await fetch(GET_API, options);

//     if (response.status === 200) {
//       const data = await response.json();
//       return data;
//     } else {
//       localStorage.removeItem("Friska_Database")
//       navigate("/sign-in")
//       return "invalid";
//     }
//   } catch (e) {
//     console.log(e.message);
//   }
// };


/* 
! Employees by Gender API
*/

// export const getEmployeesByGenderService = async (navigate) => {
//   const GET_API = `${rootDomain}Dashboard/userGenderlistcount`;
//   const authToken = await getAuthToken();
//   const options = {
//     method: "GET",
//     headers: headersWithToken(authToken),
//   };
//   console.log("options", options);

//   try {
//     const response = await fetch(GET_API, options);

//     if (response.status === 200) {
//       const data = await response.json();
//       return data;
//     } else {
//       localStorage.removeItem("Friska_Database")
//       navigate("/sign-in")
//       return "invalid";
//     }
//   } catch (e) {
//     console.log(e.message);
//   }
// };


/* 
! Employees by age API
*/

// export const getEmployeesByAgeService = async (navigate) => {
//   const GET_API = `${rootDomain}Dashboard/getEmployeesbyagecountlist`;
//   const authToken = await getAuthToken();
//   const options = {
//     method: "GET",
//     headers: headersWithToken(authToken),
//   };
//   console.log("options", options);

//   try {
//     const response = await fetch(GET_API, options);

//     if (response.status === 200) {
//       const data = await response.json();
//       return data;
//     } else {
//       localStorage.removeItem("Friska_Database")
//       navigate("/sign-in")
//       return "invalid";
//     }
//   } catch (e) {
//     console.log(e.message);
//   }
// };
