// import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";



let isUserExist;

const getLocalData = () => {
  const localData = localStorage.getItem("Friska_Database");

  return JSON.parse(localData);
};

isUserExist = getLocalData();

export const ContentProtectedRoute = () => {
  useEffect(() => {
    isUserExist = getLocalData();
  }, [isUserExist]);
  return isUserExist ? <Outlet /> : <Navigate to="/sign-in" />;
};

export const OnboardingProtectedRoute = () => {
  useEffect(() => {
    isUserExist = getLocalData();
  }, [isUserExist]);
  return isUserExist ? <Navigate to="/dashboard" /> : <Outlet />;
};
 