import { useNavigate } from "react-router-dom";
import  "./index.css"

const WelcomeFriska = () => {
    const navigate = useNavigate()

    return (
        <div className="welcomeFriskaContainer">
            <h2>Welcome to Friska Wellness portal</h2>
            <button className="blue_btn" type="button" onClick={() => navigate("/dashboard")}>Get Started</button>
        </div>
    )
}


export default WelcomeFriska;