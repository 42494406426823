import { Form, Input } from "antd";
import "./index.css";
import logo from "../../assets/images/leftNavigate/logo_lg.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getChangeForgotPassword, getForgotPassword, getOTPforForgotPassword } from "../Service/service";
import { updateJwtToken } from "../../globalDataManager/UserDetails/userDetails";
import { useDispatch } from "react-redux";

const fetchingStatus = {
  initial: "1",
  inProgress: "2",
  success: "3",
  failure: "4",
};
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [yourEmailAddress, setYourEmailAddress] = useState("");
  const [yourOtp, setYourOtp] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isWrongEmail, setIsWrongEmail] = useState(true);
  const [isWrongOtp, setIsWrongOtp] = useState(true);
  const [renderingComponent, setRenderingComponent] = useState("1")
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [changePasswordDisabled, setChangePasswordDisabled] = useState(true);
  

console.log(newPassword,"newPassword")
console.log(confirmPassword,"confirmPassword")
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailAddress = (e) => {
    const email = e.target.value;
    setYourEmailAddress(email);
    setIsValidEmail(validateEmail(email));
  };


  const validateOTP = (otp) => {
    const sixDigitOtpNumber = /^\d{6}$/;
    return sixDigitOtpNumber.test(otp);
  };

  const handleOTPDetails = (e) => {
    const otp = e.target.value;
    setYourOtp(otp);
    setIsValidEmail(validateOTP(otp));
  };

  const validateNewPassword = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleNewPassword = (e) => {
    const email = e.target.value;
    setYourEmailAddress(email);
    setIsValidEmail(validateEmail(email));
  };


  useEffect(() => {
    if (location.state?.yourEmailAddress) {
      setYourEmailAddress(location.state.yourEmailAddress);
    }
  }, [location.state?.yourEmailAddress]);

  const validateEmailOnclick = async () => {
    const forgotEmail = yourEmailAddress;
    if (isValidEmail) {
      dispatch(updateJwtToken());
      const getForgotPasswordRes = await getForgotPassword(forgotEmail, navigate);
      console.log(getForgotPasswordRes, "get Forgot Password");
      
      if (getForgotPasswordRes.status === 1 ) {
        setIsWrongEmail(false);
        setRenderingComponent("1");
      } else {
        setIsWrongEmail(true);
        setRenderingComponent("2");
      }
    }
  };

  const validateOTPOnclick = async () => {
    const forgotEmail = yourEmailAddress;
    const otp = yourOtp;
    if (isValidEmail) {
      dispatch(updateJwtToken());
      const getOTPForPasswordRes = await getOTPforForgotPassword(forgotEmail, yourOtp, navigate);
      console.log(getOTPForPasswordRes, "get Forgot Password");
      
      if (getOTPForPasswordRes.status === 1 ) {
        setIsWrongOtp(false);
        setMessage('Incorrect OTP.');
        setRenderingComponent("2");
      } else if (getOTPForPasswordRes.status === 2){
        setIsWrongOtp(false);
        setMessage('Too many attempts.');
        setRenderingComponent("2");
      } else {
        setIsWrongOtp(true);
        setRenderingComponent("3");
      }
    }
  };

  const validateChangePassword = async () => {
    const email = yourEmailAddress;
    const password = confirmPassword;
    if (isValidEmail) {
      dispatch(updateJwtToken());
      const getOTPForPasswordRes = await getChangeForgotPassword(email, password, navigate);
      console.log(getOTPForPasswordRes, "get Forgot Password");
      
      if (getOTPForPasswordRes.status === 1 ) {
        setIsWrongOtp(false);
        setRenderingComponent("3");
        
      } else {
        setIsWrongOtp(true);
        setRenderingComponent("4");
        setPasswordMatch(confirmPassword);
        
      }
    }
  };

  
  const checkPasswordStrength = (password) => {
    let strength = '';
    if (password.length < 6) {
      strength = 'Too short';
    } else if (password.length >= 6 && password.length < 8) {
      strength = 'Weak';
    } else if (password.length >= 8 && password.match(/[A-Z]/) && password.match(/[0-9]/) && password.match(/[^A-Za-z0-9]/)) {
      strength = 'Strong';
    } else if (password.length >= 8) {
      strength = 'Moderate';
    }
    setPasswordStrength(strength);
  };

 


  const handleNewPasswordChange = (event) => {
    const newPass = event.target.value;
    setNewPassword(newPass);
    validatePasswords(newPass, confirmPassword);
};

const handleConfirmPasswordChange = (event) => {
    const confirmPass = event.target.value;
    setConfirmPassword(confirmPass);
    validatePasswords(newPassword, confirmPass);
};
 
const changePasswordIsDisabled = () => (
  setChangePasswordDisabled(false)
)
  const validatePasswords = (newPass, confirmPass) => {
    if (newPass !== confirmPass) {
        setError('Passwords do not match!');
    } else {
        setError('');
        changePasswordIsDisabled()
        
    }
};

  const forgotPassword = () => (
    <div className="forgotYourPasswordContainer">
      <div className="forgotPasswordCardContainer">
      <img src={logo} alt="logo" />
      <div className="text-center">
        <h2 className="forgotPasswordTitle">Forgot your password</h2>
        <p className="forgotPasswordContent">
          Enter the email address you used when you joined and we’ll send the
          instructions to reset your password
        </p>

        <Input
          className="border-input"
          placeholder="Enter your email address"
          value={yourEmailAddress}
          onChange={handleEmailAddress}
        />
        {!isValidEmail && <p className="error-message">Please enter a valid email address.</p>} 
        {!isWrongEmail && <p className="error-message">Email not found.</p>} 
        <button className="blue_btn mt-3" type="button" onClick={validateEmailOnclick}>
          Next
        </button>
        <button className="btn-none black_text mt-2" onClick={() => navigate("/sign-in")} type="button">
          Back to log in
        </button>
      </div>
    </div>  
      </div>
    
  );

  const checkYourEmail = () => (
    <div className="forgotYourPasswordContainer">
       <div className="forgotPasswordCardContainer">
      <img src={logo} alt="logo" />
      <div className="text-center">
        <h2 className="forgotPasswordTitle">Check your email</h2>
        <p className="forgotPasswordContent">
          An email has been sent to you. We’ve sent instructions on how to reset
          your password to <b>{yourEmailAddress}</b>
        </p>
        <Input className="border-input" placeholder="Enter the verification code" type="number" value={yourOtp}
          onChange={handleOTPDetails} />
           {!isWrongOtp && <p className="error-message">{message}</p>}
        <button className="blue_btn mt-3" type="button" onClick={validateOTPOnclick}>
          Verify
        </button>
        <button className="btn-none black_text mt-2" type="button" onClick={validateEmailOnclick}>
          Send me another code
        </button>
      </div>
    </div>  
      </div>
   
  );

  const resetPassword = () => (
    <div className="forgotYourPasswordContainer">
    <div className="forgotPasswordCardContainer">
      <img src={logo} alt="logo" />
      <div className="text-center">
        <h2 className="forgotPasswordTitle">Reset your password</h2>

        <Form.Item
          name="password"
          style={{marginBottom: "16px"}}
         
          rules={[
            {
              required: true,
              message: "Enter your new password",
            },
          ]}
        >
          <Input.Password placeholder="New Password" className="loginMb_16" value={newPassword}
          onChange={handleNewPasswordChange} />
          {newPassword && (
          <p className={`password-strength ${passwordStrength.toLowerCase()}`}>
          {passwordStrength}
          </p>
        )}
        </Form.Item>
        <Form.Item
          name="password" 
          
          rules={[
            {
              required: true,
              message: "Confirm your new password",
            },
          ]}
        >
          <Input.Password placeholder="Confirm Password"  value={confirmPassword}
          onChange={handleConfirmPasswordChange}/>
          <p className="password-match-error" style={{ color: 'red' }}>{error}</p>
        </Form.Item>
        <button className="blue_btn mt-3" type="button" disabled={changePasswordDisabled} onClick={validateChangePassword}>
          Reset my password
        </button>
      </div>
    </div>
    </div>
  );

  const passwordSuccessful = () => (
    <div className="forgotYourPasswordContainer">
    <div className="forgotPasswordCardContainer">
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="35" cy="35" r="35" fill="#EFFCF3" />
        <circle cx="34.7663" cy="34.7663" r="29.1667" fill="#CFFADE" />
        <path
          d="M31.8401 41.6268C31.5782 41.8848 31.2073 42.0999 30.88 42.0999C30.5528 42.0999 30.1818 41.8741 29.9091 41.616L23.7998 35.5946L25.7417 33.6807L30.891 38.7559L44.5059 25.24L46.4151 27.1862L31.8401 41.6268Z"
          fill="#25B785"
        />
      </svg>

      <div className="text-center">
        <h2 className="forgotPasswordTitle">Password successfully reset</h2>
        <p className="forgotPasswordContent">
          Your password has been reset successfully. You can now continue to log
          in with your new password
        </p>
        <button className="blue_btn mt-3" type="button" onClick={()=> navigate("/sign-in")}>
          Back to Login
        </button>
      </div>
    </div>
    </div>
  );

  const renderFinalView = () => {
    switch (renderingComponent) {
      case "1":
        return forgotPassword();

      case "2":
        return checkYourEmail();

      case "3":
        return resetPassword();

      case "4":
        return passwordSuccessful();

      default:
        break;
    }
  };

  /*   

  ! Actual return statement that will return components content
  */
  return renderFinalView();
};

export default ForgotPassword;
