import "./index.css"
import logo from "../../../assets/images/createOrganization/logo.svg"
import sampleProfile from "../../../assets/images/createOrganization/sampleProfile.svg"
import { Button, Checkbox, Form, Input, message, Modal, Select, Steps, theme } from 'antd';
import { useEffect, useState } from "react";
import MenuItem from "antd/es/menu/MenuItem"; 
import { FormControlLabel } from "@mui/material";
import { getAccountUserDetails, getRoleTyeListService, getUsersListService, postAddUpdateemployeeService, postAddUpdateFacilityService } from "../../Service/service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateJwtToken } from "../../../globalDataManager/UserDetails/userDetails";


const OrganizationRegistration = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showForm, setShowForm] = useState(false);
    const [email, setEmail] = useState('');
    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState('');
    const [role, setRole] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [submittedDetails, setSubmittedDetails] = useState(null);
    const [userList, setUserList] = useState([]);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
    const [oragnizationAddButtonDisabled, setorganizationAddButtonDisabled] = useState(true);
    const [isAddnewUserModalOpen, setIsAddnewUserModalOpen] = useState(false);
    const [practiceName, setpracticeName] = useState("");
    const [facilityName, setFacilityName] = useState("");
    const [practicePhone, setPracticePhone] = useState("");
    const [fax, setFax] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [postalCode, setPostalCode] = useState(""); 
    const [userFirstName, setUserFirstName] = useState(""); 
    const [userSecondName, setUserSecondName] = useState(""); 
    const [addUsers, setAddUsers] = useState([]); 
    const [roleType, setRoleType] = useState([]);  
    const [roleTypeSetted, settedRoleType] = useState([]); 

    console.log(roleTypeSetted, "roleTypeSetted")


 


    console.log(userList, "userList")
    

    
  
    const handleShowForm = () => {
      setShowForm(!showForm);
    };

    useEffect(() => {
      dispatch(updateJwtToken());
      const getData = async () => {
        const userAccountRes = await getUsersListService("",navigate);
        if (userAccountRes !== "invalid" && userAccountRes) {
          setAddUsers(userAccountRes)  
          console.log(userAccountRes, "top menu response");
        }
      };
      getRoleTyeListiting()
      getData();
    }, []);

    const getRoleTyeListiting = async (event) => {
      const receivedData = await getRoleTyeListService(navigate );
  
      console.log(receivedData, "goalValue");

      if (receivedData !== "invalid") {
          // const firstItem = receivedData[0];
          // console.log(firstItem, "firstItem")
          setRoleType(receivedData);
          console.log(receivedData, "receivedData")
          
      //   updateFetchingStatus(fetchingStatus.success);
      } else {
      //   updateFetchingStatus(fetchingStatus.failure);
      }
    };
  

    useEffect(() => {
        submitCheck();
        return () => {
            setIsAddButtonDisabled(true);
        };
      }, [ userFirstName, userSecondName, emailAddress, roleType ]);


      const submitCheck = () => {
        
       
        if ( userFirstName === '' || userSecondName === '' || emailAddress.trim() === '' || roleType === '' ) {
            setIsAddButtonDisabled(true);
          } else {
            setIsAddButtonDisabled(false);
          } 
      }
      
      const handleOrganizationAddUpdateEmployee = async () => {


        const bodyContent = {  
            roleId: roleTypeSetted.toString(),  
            employeeFirstName: userFirstName, 
            employeeLastName: userSecondName,
            email: emailAddress 
          }; 
          console.log(bodyContent, "bodyContent")
    
        const postResponse = await postAddUpdateemployeeService(navigate, bodyContent);
    
        if (postResponse !== "invalid" && postResponse) {
        //   navigate(next()); 
         
        // navigate("/create-organization")
        
        } else {
          // message.error("Something went wrong");
          // navigate("/create-organization")
          message.error("Something went wrong")
        }
      };

    const handleSubmit = (e) => {
        e.preventDefault();
    
    // Create a new user object
    const newUser = { userFirstName, userSecondName, role, isAdmin, emailAddress };
    
    // Add the new user to the list
    setUserList([...userList, newUser]);
    
    // Set the submitted details to show them
    setSubmittedDetails(newUser);
    
    // Reset form fields for new entry
    setEmailAddress('');
    setUserFirstName('');
    setUserSecondName('');
    setRole('');
    setIsAdmin(false);
    setShowForm(false);
        // Optionally keep the form open or set showForm to true
      };

    const handleRoleSelectChange = (event) => {
        setRole(event);
        console.log(event)
      };

      const handleAddUserButtonClick = () => {
        if (userList.length === 0) {
            setIsAddnewUserModalOpen(true)
        } else {
          // Your logic to go to the next page
        //   next();
        navigate("/welcome-friska")
        }
      };

      const handleOk = () => {
        setIsAddnewUserModalOpen(false) 
        navigate("/welcome-friska")
      }
      const handleCancel = () => {
        setIsAddnewUserModalOpen(false)
         
      }
      const handleDelete = (index) => {
        // Create a new array excluding the user at the specified index
        const newUserList = userList.filter((_, i) => i !== index);
        // Update the state with the new user list
        setUserList(newUserList);
      };
      const validateEmail = (email) => {
        // Simple email regex for basic validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
    
      const handleChangeEmailAddress = (event) => {
        const newEmail = event.target.value;
        setEmailAddress(newEmail);
    
        if (newEmail === '') {
          setError('Email address is required.');
        } else if (!validateEmail(newEmail)) {
          setError('Please enter a valid email address.');
        } else {
          setError('');
        }
      };


      useEffect(() => {
        submitOrganizationCheck();
        return () => {
            setorganizationAddButtonDisabled(true);
        };
      }, [practiceName, practicePhone, facilityName]);


      const submitOrganizationCheck = () => {
        
       
        if (practiceName.trim() === '' || practicePhone.trim() === '' || facilityName.trim() === '') {
            setorganizationAddButtonDisabled(true);
          } else {
            setorganizationAddButtonDisabled(false);
          } 
      }

      const handleOrganizationRegistration = async () => {


        const bodyContent = {
            facilityId: 0,
            organizationName: practiceName,
            facilityName: facilityName,
            authorizedFirstName: "",
            authorizedLastName: "",
            addressLine1: address1,
            addressLine2: address2,
            city: city,
            state: state,
            country: country,
            pinCode: postalCode,
            telephone: practicePhone.toString(),
            email: ""
          }; 
    
        const postResponse = await postAddUpdateFacilityService(navigate, bodyContent);
    
        if (postResponse !== "invalid" && postResponse) {
          navigate(next());
        } else {
          message.error("Something went wrong");
        }
      };
      const handleChangeRoleType = (event) => {
        settedRoleType(event);
        // console.log(event, "(event.target.value)")
      };

      
    

      
      

    const registration = () => (
        <div>
            <h2 className="oragnization_title">Organization Registration</h2>
            <p className="oragnization_sub">Please enter your Organization details</p>

            <div className="row mt-3">
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Practice Name <span className="text-danger">*</span>
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={practiceName}
                        onChange={(event) => setpracticeName(event.target.value)}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Facility Name <span className="text-danger">*</span>
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={facilityName}
                        onChange={(event) => setFacilityName(event.target.value)}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Practice Phone <span className="text-danger">*</span>
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={practicePhone}
                        onChange={(event) => setPracticePhone(event.target.value)}
                        type="number"
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Fax
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={fax}
                        onChange={(event) => setFax(event.target.value)}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Address line 1
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={address1}
                        onChange={(event) => setAddress1(event.target.value)}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <label className="modal_label">
                        Address line 2
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={address2}
                        onChange={(event) => setAddress2(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="modal_label">
                        City
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="modal_label">
                        State
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={state}
                        onChange={(event) => setState(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="modal_label">
                        Country
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-3">
                    <label className="modal_label">
                        Postal Code
                    </label>
                    <Input
                        className="border-input outline_input"
                        value={postalCode}
                        onChange={(event) => setPostalCode(event.target.value)}
                    />
                </div>
            </div>
            <button className="organization_btn mt-3" disabled={oragnizationAddButtonDisabled} type="primary" onClick={handleOrganizationRegistration} >Continue</button>
        </div>
    )
    const addUser = () => {

       

        return (
        <div>
            <h2 className="oragnization_title">Add Users</h2>
            <p className="oragnization_sub">Add new users for your EHR</p>

            <div className="row mt-5 gap-3">
            {addUsers?.map(item => 
            <div className="addedUserContainer">
            <div className="d-flex align-items-center gap-3">
            <img src={sampleProfile} alt="profilePic"/>
            <div>
                <h5 className="adminName">{item.employeeFullName} <span>{item.role}</span></h5>
                <p className="adminMail">{item.email}</p>
                </div>
            </div>
        </div>)}
                
                {submittedDetails && (
        <div className="userList">
          {userList.map((user, index) => (
            <div key={index} className="addedUserContainer">
              <div className="d-flex align-items-center gap-3">
                <img src={sampleProfile} alt="profilePic" />
                <div>
                  <h5 className="adminName">
                    {user.userFirstName} {user.userSecondName} <span>{user.role}</span> <span className="admin_access">{user.isAdmin ? 'Admin Access' : ''}</span>
                  </h5>
                  <p className="adminMail">{user.emailAddress}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
        
                {showForm && (
        <form className="mt-3" onSubmit={(e) => handleSubmit(e)}>
            <div className="d-flex gap-3 mb-3">
            <div className="addingUser">
            <label className="modal_label">First name <span className="text-danger">*</span></label>
            <Input
          className="border-input bg-white"
          value={userFirstName} 
          onChange={(e) => setUserFirstName(e.target.value)}
        /> 
          </div>
          <div className="addingUser">
            <label className="modal_label">Last name <span className="text-danger">*</span></label>
            <Input
          className="border-input bg-white"
          value={userSecondName} 
          onChange={(e) => setUserSecondName(e.target.value)}
        />
          </div>
            </div>
          <div className="addingUserContainer">
          <div className="addingUser">
            <label className="modal_label">Email <span className="text-danger">*</span></label>
            <Input
          className="border-input bg-white"
          value={emailAddress} 
          onChange={handleChangeEmailAddress}
        />
         {error && <div className="error-message-sm ">{error}</div>}
          </div>
          <div className="addingUser">
            <label className="modal_label">Role <span className="text-danger">*</span></label>
            <Select
                    // value={medicalConditionList}
                    onChange={handleChangeRoleType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {roleType?.map(item => <MenuItem key = {item.roleId} value={item.roleId}>{item.roleName}</MenuItem>)}
                   
                  </Select>
          </div>
          {/* <div className="addingUserCheckbox">
            <FormControlLabel
              control={
                <Checkbox
                className="ps-3"
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                />
              }
              label="Admin"
            />
          </div> */}
          <button className="organization_btn" type="submit" disabled={isAddButtonDisabled} onClick={handleOrganizationAddUpdateEmployee} >
            Add
          </button></div>
        </form>
      )}
       
                <button className="btn-none add_user_btn mt-3 mb-3" type="button"  onClick={handleShowForm} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clip-path="url(#clip0_1007_12678)">
    <path d="M0.914062 6.72656H5.27344V11.0859C5.27344 11.4866 5.5993 11.8125 6 11.8125C6.4007 11.8125 6.72656 11.4866 6.72656 11.0859V6.72656H11.0859C11.4866 6.72656 11.8125 6.4007 11.8125 6C11.8125 5.5993 11.4866 5.27344 11.0859 5.27344H6.72656V0.914062C6.72656 0.51336 6.4007 0.1875 6 0.1875C5.5993 0.1875 5.27344 0.51336 5.27344 0.914062V5.27344H0.914062C0.51336 5.27344 0.1875 5.5993 0.1875 6C0.1875 6.4007 0.51336 6.72656 0.914062 6.72656Z" fill="#007AFF"/>
  </g>
  <defs>
    <clipPath id="clip0_1007_12678">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg> Add New User</button>
<Modal open={isAddnewUserModalOpen} onCancel={handleCancel} footer={[
        <button className="blue_btn w-25" key="ok" type="primary" onClick={handleOk}>
          Skip
        </button>,
        <button className="blue_btn_outline w-25" key="cancel" onClick={handleCancel}>
          Cancel
        </button>,
      ]}>
        <p className="skipText mt-3"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.999 8.0025V13.0025M11.999 22.0025C17.499 22.0025 21.999 17.5025 21.999 12.0025C21.999 6.5025 17.499 2.0025 11.999 2.0025C6.49902 2.0025 1.99902 6.5025 1.99902 12.0025C1.99902 17.5025 6.49902 22.0025 11.999 22.0025Z" stroke="#F2AD3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9941 15.9975H12.0061" stroke="#F2AD3D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 Are you sure want to skip? You can add the users
        later in the user menu.</p> 
      </Modal>

            </div>
           <div className="d-flex gap-3 mt-3"> 

<button className="organization_btn_outline" onClick={() => prev()}>Previous</button>
<button className="organization_btn" type="primary" onClick={handleAddUserButtonClick}>Continue</button>

           </div>
        </div>
    )}
    const enableMfa = () => (
        <div>enableMfa
            <div className="d-flex gap-3 mt-3"> 

            <button className="organization_btn_outline" onClick={() => prev()}>Previous</button>
            <button className="organization_btn" onClick={() => message.success('Processing complete!')}>Done</button>
           </div>
            
        </div>
    )

    const steps = [
        {
            title: 'Registration',
            content: registration(),
        },
        {
            title: 'Add Users',
            content: addUser(),
        },
        // {
        //     title: 'Enable MFA',
        //     content: enableMfa(),
        // },
    ];

    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));



    return (
        <div>
            <div className="stepsContainer">
                <Steps current={current} items={items} />

                <img src={logo} alt="logo" />

            </div>
            <div className="registration_Container">


                <div className="registration_body">
                    {steps[current].content}
                    {/* <div className="d-flex gap-3 align-items-center">
                        {current < steps.length - 1 && (
                            <button className="organization_btn" type="primary" onClick={() => next()}>
                                Continue
                            </button>
                        )}
                        {current === steps.length - 1 && (
                            <button className="organization_btn" onClick={() => message.success('Processing complete!')}>
                                Done
                            </button>
                        )}
                        {current > 0 && (
                            <button className="organization_btn_outline" onClick={() => prev()}
                            >
                                Previous
                            </button>
                        )}
                    </div> */}
                </div>


            </div>
        </div>
    )
}

export default OrganizationRegistration;