import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jwtToken: "",
  selectedMenu: ""
};

export const userDetails = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    updateJwtToken: (state, action) => {
      const currentToken = JSON.parse(localStorage.getItem("Friska_Database"));

      state.jwtToken = currentToken;
    },
    updateSelectedMenu: (state, action) => {
      console.log(action, "action")
      state.selectedMenu = action.payload;
    },
  },
});

export const jwtToken = (state) => state.userDetails.jwtToken;
export const selectedMenu = (state) => state.userDetails.selectedMenu;
export const { updateJwtToken, updateSelectedMenu } = userDetails.actions;
export default userDetails.reducer;
