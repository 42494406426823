import LeftNavigationBar from "../../shareableResources/LeftNavigationBar";
import "./index.css";
import plusIcon from "../../../assets/images/rightContainer/plusIcon.svg";
import communityIcon from "../../../assets/images/rightContainer/communityIcon.svg";
import closeBtnIcon from "../../../assets/images/rightContainer/closeBtnIcon.svg";
import noCommunityCardIcon from "../../../assets/images/rightContainer/noCommunityCardIcon.svg";
import StickyLabel from "../../shareableResources/StickyLabel";
import { useLocation, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import RightTopHeaderMenu from "../../shareableResources/RightTopHeaderMenu";
import { Input, message, Modal, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import sampleAvatarIcon from "../../../assets/images/rightContainer/sampleAvatarIcon.svg"; 
import { v4 as uuidv4 } from 'uuid';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import {
  getAllMedicalConditionService,
  getCheifComplaintService,
  getPatientChartService, 
  getPatientSummaryService,
  getPatientVitalsService,
  getPatientWaterService,
  postAddCheifComplaintService,
} from "../../Service/service";
import { useDispatch } from "react-redux";
import { updateJwtToken, updateSelectedMenu } from "../../../globalDataManager/UserDetails/userDetails";
import { Gauge } from "@mui/x-charts";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; 
import Switch from '@mui/material/Switch';


const data = [
  { x: 100, y: 200, z: 200 },
  { x: 120, y: 100, z: 260 },
  { x: 170, y: 300, z: 400 },
  { x: 140, y: 250, z: 280 },
  { x: 150, y: 400, z: 500 },
  { x: 110, y: 280, z: 200 },
];



const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();

  const [medicalConditionList, setMedicalConditionList] = useState([]);

  const [selectedMedicalCondition, setSelectedMedicalCondition] = useState("");
  const [isCheifComplaintCard, isCheifComplaintCardActive] = useState(true);
  const [notes, setNotes] = useState("");
  const [patientWaterDetail, setPatientWaterDetails] = useState("");
  const [patientNutritionDetail, setPatientNutritionDetails] = useState("");
  const [patientVitalsDetail, setPatientVitalsDetails] = useState([]);
  const [cheiftComplaintDetails, setCheiftComplaintDetails] = useState([]);
  const [patientSummaryDetails, setPatientSummaryDetails] = useState({
    heartRate: "",
    heartRateLastSync: "",
    bloodGlucose: "",
    bloodGlucoseLastSync: "",
    bloodPressure: "",
    bloodPressureLastSync: "",
    bloodOxygen: "",
    bloodOxygenLastSync: "",
    steps: "",
    stepsGoal: "",
    calories: "",
    caloriesGoal: "",
    sleep: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: "",
    },
    sleepGoal: "",
    activityTotalHours: "",
    activityTotalCalories: "",
    enrolledBy: "",
    enrolledProgram: "",
    enrolledPDate: "",
    primaryIns: "", 
  });
  console.log(notes)
  const [patientChartDetails, setPatientChartDetails] = useState({
    patientFullName: "",
    patientId: "",
    dateOfBirth: "",
    mobile: "",
    patientAge: "",
    genderDesc: "",
    enrolledOn: "",
    primaryInsurance: "",

    // Basic information
    patientFirstName: "",
    patientMiddleName: "",
    patientLastName: "",
    dateOfBirth: "",
    sexualOrientation: "",
    maritalStatus: "",
    race: "",
    ssn: "", 

    // Contact information
    worktelephone: "",
    mobile: "",
    email: "",
    othertelephone: "",
    patientAddress: {
      address1: "",
    },

    // insurance details
    patientInsurance: {
      insuranceCompany: "",
      subscribedInsured: "",
      groupPolicyNo: "",
      programName: "",
      addressLine1: "",
    },
  });
  const [modal2Open, setModal2Open] = useState(false);
  console.log(patientSummaryDetails, "selectedMedicalCondition");
  const patientId = location.state.patientId ?? 0;
  
  useEffect(() => {
    dispatch(updateJwtToken()); 
    dispatch( updateSelectedMenu("users")); 

    const getData = async () => {
      const patientChartRes = await getPatientChartService(patientId, navigate); 
 
      setPatientChartDetails(patientChartRes);
      if (patientChartRes !== "invalid" && patientChartRes) {
        setPatientChartDetails(patientChartRes);
        console.log(patientChartRes, "patientChartRes");
      }
    };

    getData();
  }, []);
  const todayDate = new Date();
  const yearSummary = todayDate.getFullYear();
const monthSummary = String(todayDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
const daySummary = String(todayDate.getDate()).padStart(2, '0');

const formattedDateSummary = `${yearSummary}-${monthSummary}-${daySummary}`;
  useEffect(() => {
    const getData = async () => {
      const patientSummeryRes = await getPatientSummaryService(patientId,formattedDateSummary, navigate);
      setPatientSummaryDetails(patientSummeryRes);
      if (patientSummeryRes !== "invalid" && patientSummeryRes) {
        setPatientSummaryDetails(patientSummeryRes);
        console.log(patientSummeryRes, "patientSummeryRes");
      }
    };

    getData();
  }, [patientId,formattedDateSummary, navigate]);

  useEffect(() => {
    const getData = async () => {
      const patientWaterRes = await getPatientWaterService(patientId,formattedDateSummary, navigate);
      setPatientWaterDetails(patientWaterRes);
      if (patientWaterRes !== "invalid" && patientWaterRes) {
        setPatientWaterDetails(patientWaterRes);
        console.log(patientWaterRes, "patientWaterRes");
      }
    };

    getData();
  }, [patientId,formattedDateSummary, navigate]);
 

  useEffect(() => {
    const getData = async () => {
      const patientVitalsRes = await getPatientVitalsService(patientId,formattedDateSummary, navigate);
      setPatientVitalsDetails(patientVitalsRes);
      if (patientVitalsRes !== "invalid" && patientVitalsRes) {
        setPatientVitalsDetails(patientVitalsRes);
        console.log(patientVitalsRes, "patientVitalsRes");
      }
    };

    getData();
  }, [patientId,formattedDateSummary, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const medicalConditionResponse = await getAllMedicalConditionService();
        if (
          medicalConditionResponse !== "invalid" &&
          medicalConditionResponse
        ) {
          setMedicalConditionList(medicalConditionResponse);
          console.log(medicalConditionResponse, "medicalConditionResponse");
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchData();
  }, []);

  const handleChangeMedicalCondition = (event) => {
    setSelectedMedicalCondition(event.target.value);
  };

  const maxSteps = patientWaterDetail.waterGoalVolume;
const currentStep = patientWaterDetail.water; // This should be the step value from 0 to 8
const progressPercentage = (currentStep / maxSteps) * 100;


  const maxCal = patientSummaryDetails.caloriesGoal;
const currentCal = patientSummaryDetails.calories; // This should be the step value from 0 to 8
const progressPercentageCal = (currentCal / maxCal) * 100;

 
  const renderCheifDate = (actionDate) => {
    const date = new Date(actionDate);

    const hours = date.getHours();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate
  }

  const getCheifComplaintData = async () => {
    const cheifComplaintRes = await getCheifComplaintService(patientId, navigate);
    setCheiftComplaintDetails(cheifComplaintRes);
    if (cheifComplaintRes !== "invalid" && cheifComplaintRes) {
      setCheiftComplaintDetails(cheifComplaintRes);
      console.log(cheifComplaintRes, "cheifComplaintRes");
    }
  };

  useEffect(() => {
    

    getCheifComplaintData();
  }, []);

  const getFirstAndLastLetters = (name) => {
    const words = name.trim().split(' ');
    if (words.length === 0) return ''; // Handle case where name might be empty
  
    const firstLetter = words[0][0];
    const lastLetter = words[words.length - 1][0];
    console.log(firstLetter , lastLetter)
  
    return `${firstLetter ?? ""}${lastLetter ?? ""}`;
  };

  
  const date = new Date(cheiftComplaintDetails.createdDate);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Converts 0 hours to 12
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    console.log(formattedTime, "timeeeeeeeeeeeeeeeee")

  const patientCard = () => (
    <div
      className="patientCardCard"
      onClick={() => navigate("/instructor-list")}
    >
      <div className="patientCardListCard">
        <div className="d-flex gap-2">
          <h1 className="userNameCard">BG</h1>
          <div className="patientCardCardWidth">
            <h2 className="patientCardHead">Brooklyn Simmons</h2>
            <p className="patientCardSub">GDI001</p>
          </div>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Date of Birth/Gender</h2>
          <p className="patientCardSub">Mar 15, 1966</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Email address</h2>
          <p className="patientCardSub">gokul@kalari.com</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Phone</h2>
          <p className="patientCardSub">(951) 4567985664</p>
        </div>
        <div className="patientCardCardWidth">
          <h2 className="patientCardHead">Wellness Status</h2>
          <p className="patientCardSub">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
                fill="#13D090"
              />
            </svg>{" "}
            Enrolled
          </p>
        </div>
      </div>
    </div>
  );
  const props = {
    name: "file",
    accept: "image/*", // Ensures only image formats are accepted
    beforeUpload: (file) => {
      // Simulate processing the file locally
      console.log("File before upload:", file);

      // Simulate a successful upload with a timeout
      setTimeout(() => {
        message.success(`${file.name} file uploaded successfully`);
      }, 1000);

      // Return false to prevent the default upload action
      return false;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log("Uploading:", info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log("Response:", info.file.response);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log("Error:", info.file.error);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onError(err) {
      console.error("Upload error:", err);
    },
  };
  const [open, setOpen] = useState(false);
  const [openAddChiefComplaint, setopenAddChiefComplaint] = useState(false);
  const [openEnroll, setopenEnroll] = useState(false);
  const [scroll, setScroll] = useState("paper");
console.log(patientVitalsDetail, "patientVitalsDetail")
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const handleCloseAddCommunity = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddChiefComplaint(false);
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const addedCommunityMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new Community",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Community Added Successfully",
          1.5,
          setopenAddChiefComplaint(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const addedEnrollMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding Wellness enrollment",
        duration: 1.5,
      })
      .then(() =>
        message.success("Enrolled successfully", 1.5, setopenEnroll(false))
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const [isSchoolCard, isSchoolCardActive] = useState(true);

  const emptySchoolCard = () => (
    <div className="emptySchoolCard">
      <img src={noCommunityCardIcon} alt="noCommunityCardIcon" />
      <p className="noCommunityText">No Community added yet</p>
    </div>
  );
  const handleCloseAddInstructor = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenAddChiefComplaint(false);
    }
  };
  const handleCloseEnroll = (event, reason) => {
    if (reason !== "backdropClick") {
      setopenEnroll(false);
    }
  };

  const [openVitalsDialog, setOpenVitalsDialog] = useState(false);
  const handleOpenVitalsDialog = () => setOpenVitalsDialog(true);
  const handleCloseVitalsDialog = () => setOpenVitalsDialog(false);

  const chiefComplaint = async () => {
    const bodyContent = {
      UserHealthConditionID: 0,
      MemberId : patientChartDetails.patientId,
      ConditionCode: selectedMedicalCondition,
      Notes : notes,
    };
  
    console.log(bodyContent, "bodyycontent")
    try {
       const response = await postAddCheifComplaintService(
        navigate,
        bodyContent
      );
  
      if (response !== "invalid" && response) { // check if response status is 200-299
        return true;
      } else {
        // Handle error response
        console.error('Failed to add chief complaint', response.statusText);
        return false;
      }
    } catch (error) {
      // Handle network or other errors
      console.error('An error occurred while adding chief complaint', error);
      return false;
    }
  };
  
  const addedInstructorMessage = async () => {
    const success = await chiefComplaint();
    
  
    if (success) {
      messageApi
        .open({
          type: "loading",
          content: "Adding new Chief Complaint",
          duration: 1.5,
        })
        .then(() =>
          message.success(
            "Chief Complaint Added Successfully",
            1.5,
            () => {setopenAddChiefComplaint(false)
              getCheifComplaintData()
            }
          )
          
        );
      // .then(() => message.info('Community Added Successfully', 2.5));
    } else {
      message.error("Failed to add Chief Complaint");
    }
  };
  const addedEnrollPopupMessage = () => {
    messageApi
      .open({
        type: "loading",
        content: "Adding new Instructor",
        duration: 1.5,
      })
      .then(() =>
        message.success(
          "Instructor Added Successfully",
          1.5,
          setopenEnroll(false)
        )
      );
    // .then(() => message.info('Community Added Successfully', 2.5));
  };
  const basicInformation = () => (
    <div className="row row-gap-3 modal_container">
      <h3 className="infoTitle">Basic Information</h3>
      <div className="col-md-4">
        <label className="modal_label">
          First name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Middle name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Last name <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Date of Birth <span className="text-danger">*</span>
        </label>
        <Input type="date" className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Gender <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Sexual orientation <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Marital Status <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Race <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          SSN <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <h3 className="infoTitle   ">Contact information</h3>

      <div className="col-md-4">
        <label className="modal_label">
          Home telephone <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Home telephone <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Mobile <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Email <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Other telephone <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <h3 className="infoTitle   ">Address details</h3>
      <div className="col-md-6">
        <label className="modal_label">
          Address line 1 <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Address line 2</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">County</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">City</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">State</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Country</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Zip</label>
        <Input className="border-input" />
      </div>
    </div>
  );

  const documents = () => (
    <div className="row row-gap-3   modal_container">
      <div className="col-md-4">
        <label className="modal_label">
          Insurance type <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Insurance category <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Insurance company <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          Relationship to patient <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">
          SSN <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Last Name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Fist name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Middle name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Sex</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Date of birth</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Middle name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Sex</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Subscribed insured</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Patient insured</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Copay($)</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">policy/FECA</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Group policy #</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Group policy #</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Plan /Program name</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Billing method</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Effective date</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">Termination date</label>
        <Input className="border-input" />
      </div>
      <h2 className="infoTitle mb-2">Address details</h2>

      <div className="col-md-6">
        <label className="modal_label">
          Address line 1 <span className="text-danger">*</span>
        </label>
        <Input className="border-input" />
      </div>
      <div className="col-md-6">
        <label className="modal_label">Address line 2</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">County</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">City</label>
        <Input className="border-input" />
      </div>
      <div className="col-md-4">
        <label className="modal_label">State</label>
        <Input className="border-input" />
      </div>
    </div>
  );

  const Dashboard = () => (
    <div className="container-fluid">
       {/* <div className="patientChartTopHeader">
          
          <div className="profileDetailsContainer"> 
            <h3 className='myAccountUserName_chart'>{getFirstAndLastLetters(patientChartDetails?.patientFullName ?? "-")}</h3>
            <div>
              <h2 className="patientFullName">{patientChartDetails?.patientFullName}</h2>
              <div className="profileDetailsContainerInner">
                <p className="patientHeaderTitle">{patientChartDetails?.genderDesc}</p>
                <div className="horizontalProfileInnerLine"></div>
                <p className="patientHeaderTitle">{patientChartDetails?.patientAge} years old</p>
                <div className="horizontalProfileInnerLine"></div>
                <p className="patientHeaderTitle">{patientChartDetails?.dateOfBirth} </p>
              </div>
            </div>
          </div>
          <div className="enrolledContainer">
          <p className="patientHeaderTitle">Enrolled on: <span>{patientSummaryDetails.enrolledPDate ?? "-"}</span></p>
          </div>
          <div className="primaryInsuranceContainer">
          <p className="patientHeaderTitle">Primary Ins: <span>{patientSummaryDetails.primaryInsurance ?? "-"}</span></p>
          </div>
        </div> */}
        <div className="userTopDetailsContainer">
        <h3 className='UserProfileName'>{getFirstAndLastLetters(patientChartDetails?.patientFullName ?? "-")}</h3>
        <div className="w-100">
          <div className="d-flex justify-content-between w-100 bd-btm">
            <div>
              <h3 className="userProfileTitle">Dr David JD Adams</h3>
              <p className="userProfileSub">Role</p>
            </div>
            <p className="active">Active</p>
          </div>
          <div className="d-flex gap-5">
          <div>
              <h3 className="userProfileTitle">E-mail</h3>
              <p className="userProfileSub">davidadams@gmail.com</p>
            </div>
            <div>
              <h3 className="userProfileTitle">Phone</h3>
              <p className="userProfileSub">12-5448786625</p>
            </div>
             
          </div>
        </div>
        </div>
      <div className="dashboardMainContainer">
      <div className="container-fluid bg-white mb-5">
      <h3 className="dashboardCardTitle">Basic details</h3>
      <div className="demographicsContentContainer userContainer">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex">
            <p className="demographicTitle">First name</p>
            <p className="demographicData">
              {patientChartDetails?.patientFirstName ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Last name</p>
            <p className="demographicData">
              {patientChartDetails?.patientMiddleName ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Middle name</p>
            <p className="demographicData">
              {patientChartDetails?.patientLastName ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">NPI ID</p>
            <p className="demographicData">
              {patientChartDetails?.dateOfBirth ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Medicare ID</p>
            <p className="demographicData">
              {patientChartDetails?.genderDesc ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">SSN ID</p>
            <p className="demographicData">
              {patientChartDetails?.sexualOrientation ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Preferred Language</p>
            <p className="demographicData">
              {patientChartDetails?.maritalStatus ?? "-"}
            </p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Mother’s Maiden Name</p>
            <p className="demographicData">{patientChartDetails?.race ?? "-"}</p>
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          
          <div className="d-flex">
            <p className="demographicTitle">Sex</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Tax ID</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Medicaid ID</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Credentials</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Language</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Fee Schedule to use</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
          <div className="d-flex">
            <p className="demographicTitle">Website Name</p>
            <p className="demographicData">{patientChartDetails?.ssn ?? "-"}</p>
          </div>
        </div>
      </div>
      <hr />
      <h3 className="dashboardCardTitle">Contact details</h3>

      <div className="demographicsContentContainer userContainer">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex">
          <p className="demographicTitle">Email</p>
          <p className="demographicData">
            {patientChartDetails?.worktelephone ?? "-"}
          </p>
        </div>
        <div className="d-flex">
          <p className="demographicTitle">Primary Phone</p>
          <p className="demographicData">{patientChartDetails?.mobile ?? "-"}</p>
        </div>
        <div className="d-flex">
          <p className="demographicTitle">Home</p>
          <p className="demographicData">{patientChartDetails?.mobile ?? "-"}</p>
        </div>
         
      </div>
        <div className="d-flex flex-column gap-2">
        <div className="d-flex">
          <p className="demographicTitle">Mobile</p>
          <p className="demographicData">
            {patientChartDetails?.worktelephone ?? "-"}
          </p>
        </div>
        <div className="d-flex">
          <p className="demographicTitle">Work</p>
          <p className="demographicData">{patientChartDetails?.mobile ?? "-"}</p>
        </div>
        <div className="d-flex">
          <p className="demographicTitle">Fax</p>
          <p className="demographicData">{patientChartDetails?.mobile ?? "-"}</p>
        </div>
         
      </div>
      </div>
      
      <hr />
      <h3 className="dashboardCardTitle">Address details</h3>
      <div className="demographicsContentContainer">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex">
            <p className="demographicTitle">Address</p>
            <p className="demographicData">
              {patientChartDetails?.patientInsurance.insuranceCompany ?? "-"}
            </p>
          </div>
           
        </div>
        
      </div>
    </div>
      

       

        
      </div>
      
    </div>
  );
  const cheifComplaintComponent = (healthConditionTitle, notes, recordedTime) => (
    <div className="comaplaintContainer" key={uuidv4()}>
    <p className="cheif_complaint_title">{healthConditionTitle}</p>
    <p className="records"> {notes}</p>
    <p className="records pt-1">Recorded - {recordedTime}</p>
  </div>
  )

  const patienDemographics = () => (
    <div className="container-fluid bg-white mb-5 userProfileSecurity">
      <h3 className="dashboardCardTitle">Password <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M10.0001 18.3333C14.6026 18.3333 18.3334 14.6025 18.3334 9.99996C18.3334 5.39746 14.6026 1.66663 10.0001 1.66663C5.39758 1.66663 1.66675 5.39746 1.66675 9.99996C1.66675 14.6025 5.39758 18.3333 10.0001 18.3333Z" fill="#94A3B8"/>
<path d="M10.0001 11.4583C10.3417 11.4583 10.6251 11.175 10.6251 10.8333V6.66663C10.6251 6.32496 10.3417 6.04163 10.0001 6.04163C9.65841 6.04163 9.37508 6.32496 9.37508 6.66663V10.8333C9.37508 11.175 9.65841 11.4583 10.0001 11.4583ZM10.7667 13.0158C10.7251 12.9158 10.6667 12.8241 10.5917 12.7408C10.5084 12.6658 10.4167 12.6075 10.3167 12.5658C10.1139 12.4824 9.8863 12.4824 9.68341 12.5658C9.58341 12.6075 9.49175 12.6658 9.40841 12.7408C9.33341 12.8241 9.27508 12.9158 9.23341 13.0158C9.19175 13.1158 9.16675 13.2241 9.16675 13.3325C9.16675 13.4408 9.19175 13.5491 9.23341 13.6491C9.27508 13.7575 9.33341 13.8408 9.40841 13.9241C9.49175 13.9991 9.58341 14.0575 9.68341 14.0991C9.78341 14.1408 9.89175 14.1658 10.0001 14.1658C10.1084 14.1658 10.2167 14.1408 10.3167 14.0991C10.4167 14.0575 10.5084 13.9991 10.5917 13.9241C10.6667 13.8408 10.7251 13.7575 10.7667 13.6491C10.8084 13.5491 10.8334 13.4408 10.8334 13.3325C10.8334 13.2241 10.8084 13.1158 10.7667 13.0158Z" fill="#475569"/>
</svg>
</h3>
      <div className="demographicsContentContainer">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex">
            <p className="demographicTitle">Last updated: 01/18/2023 09:00 AM</p>
             
          </div>
          <p className="resetPassword">Reset password</p>
         
        </div>
         
      </div>
    
      <h3 className="dashboardCardTitle">User access <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M10.0001 18.3333C14.6026 18.3333 18.3334 14.6025 18.3334 9.99996C18.3334 5.39746 14.6026 1.66663 10.0001 1.66663C5.39758 1.66663 1.66675 5.39746 1.66675 9.99996C1.66675 14.6025 5.39758 18.3333 10.0001 18.3333Z" fill="#94A3B8"/>
<path d="M10.0001 11.4583C10.3417 11.4583 10.6251 11.175 10.6251 10.8333V6.66663C10.6251 6.32496 10.3417 6.04163 10.0001 6.04163C9.65841 6.04163 9.37508 6.32496 9.37508 6.66663V10.8333C9.37508 11.175 9.65841 11.4583 10.0001 11.4583ZM10.7667 13.0158C10.7251 12.9158 10.6667 12.8241 10.5917 12.7408C10.5084 12.6658 10.4167 12.6075 10.3167 12.5658C10.1139 12.4824 9.8863 12.4824 9.68341 12.5658C9.58341 12.6075 9.49175 12.6658 9.40841 12.7408C9.33341 12.8241 9.27508 12.9158 9.23341 13.0158C9.19175 13.1158 9.16675 13.2241 9.16675 13.3325C9.16675 13.4408 9.19175 13.5491 9.23341 13.6491C9.27508 13.7575 9.33341 13.8408 9.40841 13.9241C9.49175 13.9991 9.58341 14.0575 9.68341 14.0991C9.78341 14.1408 9.89175 14.1658 10.0001 14.1658C10.1084 14.1658 10.2167 14.1408 10.3167 14.0991C10.4167 14.0575 10.5084 13.9991 10.5917 13.9241C10.6667 13.8408 10.7251 13.7575 10.7667 13.6491C10.8084 13.5491 10.8334 13.4408 10.8334 13.3325C10.8334 13.2241 10.8084 13.1158 10.7667 13.0158Z" fill="#475569"/>
</svg></h3>
      <div className="d-flex flex-column gap-2">
      <p className="demographicTitle"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3337 9.99984C18.3337 14.5998 14.6003 18.3332 10.0003 18.3332C5.40033 18.3332 1.66699 14.5998 1.66699 9.99984C1.66699 5.39984 5.40033 1.6665 10.0003 1.6665C14.6003 1.6665 18.3337 5.39984 18.3337 9.99984Z" stroke="#793996" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0919 12.65L10.5086 11.1083C10.0586 10.8416 9.69189 10.2 9.69189 9.67497V6.2583" stroke="#793996" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  Last login: Aug 17, 2023 10:01 am</p>
  <h3 className="userProfileTitle mt-3">User Status</h3>
  <FormControlLabel control={<Switch defaultChecked />} label="Active" />
  
  <h3 className="userProfileTitle mt-3">Admin access</h3>
  <FormControlLabel control={<Switch defaultChecked />} label="Enable" />
  
  
      </div>
        
    </div>
  );
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Demographics",
      children: basicInformation(),
    },
    {
      key: "2",
      label: "Insurance details",
      children: documents(),
    },
  ];

  const chart = [
    {
      key: "1",
      label: "Profile",
      children: Dashboard(),
    },
    {
      key: "2",
      label: "Security",
      children: patienDemographics(),
    },
  ];

 
  return (
    <div className="d-flex">
      <LeftNavigationBar />
      <div className="rightDashboard_container bg-grey">
        <RightTopHeaderMenu  />

        {/* <StickyLabel /> */}
        <div className="patientChartTabs">
          <Tabs defaultActiveKey="1" items={chart} onChange={onChange} />
        </div>
        
        {/* <div className="patientChartHeaderContainer">
          <div className="d-flex   gap-5">
            <div className="d-flex gap-3">
              <img src={sampleAvatarIcon} alt="AvatarIcon" />
              <div>
                <h2 className="patientChartName">
                  {patientChartDetails.patientFullName}
                </h2>
                <p className="patientChartIdNum">
                  ID#: {patientChartDetails.patientId}
                </p>
              </div>
            </div>
            <div>
              <div className="d-flex gap-3">
                <p className="patientChartDetails">
                  {patientChartDetails.dateOfBirth}{" "}
                </p>
                <p className="patientChartDetails">
                  {patientChartDetails.patientAge} Years
                </p>
                <p className="patientChartDetails">
                  {patientChartDetails.genderDesc}
                </p>
              </div>
              <p className="patientChartDetails">
                {patientChartDetails.mobile}
              </p>
            </div>
            <div>
              <p className="patientChartDetails">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99935 6.66699V10.8337M9.99935 18.3337C14.5827 18.3337 18.3327 14.5837 18.3327 10.0003C18.3327 5.41699 14.5827 1.66699 9.99935 1.66699C5.41602 1.66699 1.66602 5.41699 1.66602 10.0003C1.66602 14.5837 5.41602 18.3337 9.99935 18.3337Z"
                    stroke="#FF8A65"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.99609 13.333H10.0036"
                    stroke="#FF8A65"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Pending
              </p>
            </div>
          </div>
        </div> */}
        

        <Dialog
          className="right_popup"
          open={openAddChiefComplaint}
          onClose={handleCloseAddInstructor}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <div>
            <div className="d-flex w-100 align-items-center justify-content-between modal_sticky_top">
              <h1 className="modal_title">Chief complaint</h1>
              <button
                className="btn-none"
                type="button"
                onClick={handleCloseAddInstructor}
              >
                <img src={closeBtnIcon} alt="closeBtnIcon" />
              </button>
            </div>
            {contextHolder}

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <label className="modal_label">Medical condition</label>
                  <br />
                  <Select
                    // value={medicalConditionList}
                    onChange={handleChangeMedicalCondition}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {medicalConditionList?.map((item) => (
                      <MenuItem
                        key={item.healthConditionId}
                        value={item.conditionCode}
                      >
                        {item.medicalConditionName}
                      </MenuItem>
                    ))}
                  </Select>
                  <label className="modal_label">Notes</label>
                  <br />
                  <TextArea
                    classNames="bgwhite_input"
                    className="border-input no-resize"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    rows={4}
                  />
                </div>
              </div>
              {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
            </div>
          </div>

          <DialogActions>
            <button className="cancel_btn" onClick={handleCloseAddInstructor}>
              Cancel
            </button>
            <button className="add_btn" onClick={addedInstructorMessage}>
              Save
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="right_popup"
          open={openEnroll}
          onClose={handleCloseEnroll}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <div>
            <div className="d-flex w-100 align-items-center justify-content-between modal_sticky_top">
              <h1 className="modal_title">Wellness enrollment</h1>
              <button
                className="btn-none"
                type="button"
                onClick={handleCloseEnroll}
              >
                <img src={closeBtnIcon} alt="closeBtnIcon" />
              </button>
            </div>
            {contextHolder}

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <label className="modal_label">Patient name</label>
                  <Input className="border-input" />
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Email address</label>
                  <Input className="border-input" />
                </div>
                <div className="col-md-12">
                  <label className="modal_label">Wellness program</label>
                  <br />
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                  <label className="modal_label">Notes</label>
                  <br />
                  <TextArea
                    classNames="bgwhite_input"
                    className="border-input no-resize"
                    rows={4}
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Sending App invite link  via email"
                  />
                </div>
              </div>
              {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
            </div>
          </div>

          <DialogActions>
            <button className="cancel_btn" onClick={handleCloseEnroll}>
              Cancel
            </button>
            <button className="add_btn" onClick={addedEnrollMessage}>
              Save
            </button>
          </DialogActions>
        </Dialog>
        <div class="stickyLabel bg-grey"><p class="stickyLabelContent">© 2024 <b>HFWL</b> EHR. All Rights Reserved</p></div>
      </div>
    </div>
  );
};

export default UserProfile;
