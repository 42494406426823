import "./index.css";
import logo from "../../../assets/images/leftNavigate/logo.svg";
import myPatientsIcon from "../../../assets/images/leftNavigate/myPatientsIcon.svg";
import instructorIcon from "../../../assets/images/leftNavigate/instructorIcon.svg";
import myLibraryIcon from "../../../assets/images/leftNavigate/myLibraryIcon.svg";
import reportIcon from "../../../assets/images/leftNavigate/reportIcon.svg";
import supportIcon from "../../../assets/images/leftNavigate/supportIcon.svg";
import KalariGroupIcon from "../../../assets/images/leftNavigate/KalariGroupIcon.svg";
import usersIcon from "../../../assets/images/leftNavigate/usersIcon.svg";
import collapseBtn from "../../../assets/images/leftNavigate/collapseBtn.svg";
import dashboardIcon from "../../../assets/images/leftNavigate/dashboardIcon.svg";
import wellnessProgramIcon from "../../../assets/images/leftNavigate/wellnessProgramIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectedMenu, updateSelectedMenu } from "../../../globalDataManager/UserDetails/userDetails";
import { useEffect } from "react";
import logo_lg from "../../../assets/images/leftNavigate/logo_lg.svg"

const LeftNavigationBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const userSelectedMenu = useSelector(selectedMenu)

  useEffect(() => {

  },[userSelectedMenu])

  const handleLogout = () => {
    localStorage.removeItem('Friska_Database'); // Clear the token or relevant data from localStorage
    navigate('/sign-in'); // Redirect to the login page after logout
  };

  const menuItemClickEventHandler = (url, menuText) => {
    dispatch(updateSelectedMenu(menuText))
    navigate(url)
  }


  const navigateBtn = (navIcon, navContent, url, isActive, menuText) => (
    <button className= {isActive ? "leftNav_btn_active" : "leftNav_btn"} onClick={() => menuItemClickEventHandler(url, menuText)}>
      <img className="btn_icon" src={navIcon} alt="schoolsIcon" /> {navContent}
    </button>
  );


  return (
    <div className="leftNavigate_container">
      <div className="d-flex flex-column left_top">
      <div className="m-auto d-block pb-5">
      <img src={logo_lg} alt="logo" />
      </div>


        {navigateBtn(dashboardIcon, "Dashboard", "/dashboard", userSelectedMenu === "dashboard", "dashboard")}
        {navigateBtn(myPatientsIcon, "My Patients", "/my-patient", userSelectedMenu === "mypatient", "mypatient")}
        {/* {navigateBtn(wellnessProgramIcon, "Wellness program", "/wellness-program", userSelectedMenu === "wellnessprogram", "wellnessprogram")} */}
        {navigateBtn(usersIcon, "Users", "/users", userSelectedMenu === "users", "users")}
        {/* {navigateBtn(myLibraryIcon, "My Library", "/my-library")}
        {navigateBtn(reportIcon, "Reports", "/reports")}
        {navigateBtn(supportIcon, "Support")} */}
        {/* {navigateBtn(supportIcon, "Approval System")} */}
      </div>
      {/* <div className="leftNav_bottom">
        <Dropdown
          menu={{
            items,
          }}
          placement="topLeft"
        >
          <div className="d-flex align-items-center gap-2">
            <img src={KalariGroupIcon} alt="KalariGroupIcon" />
            <p className="kalariGroup">Kalari Group</p>
          </div>
        </Dropdown>

        <button className="btn-none">
          <img src={collapseBtn} alt="collapseBtn" />
        </button>
      </div> */}
    </div>
  );
};

export default LeftNavigationBar;
