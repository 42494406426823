import { useEffect, useState } from "react";
import "./index.css"
import { Form, Input } from "antd";
import logo from "../../assets/images/createOrganization/logo.svg"
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { getAccountUserDetails, getRoleTyeListService, getUsersListService, postAddUpdateemployeeService } from "../Service/service";
import { useDispatch } from "react-redux";
import { updateJwtToken } from "../../globalDataManager/UserDetails/userDetails";

const SelectRoleType = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

  const [firstName, setfirstName] = useState(''); 
  const [lastName, setlastName] = useState(''); 
  const [roleType, setRoleType] = useState([]);  
  const [roleTypeSetted, settedRoleType] = useState([]);  
  const [employeeId, setEmployeeId] = useState("")
  const [isDisabled, setisDisabled] = useState(false);

  console.log(roleType, "employeeId")

  useEffect(() => {
    dispatch(updateJwtToken());
    const getData = async () => {
      const userAccountRes = await getAccountUserDetails(navigate);
      if (userAccountRes !== "invalid" && userAccountRes) {
        setEmployeeId(userAccountRes.employeeId);  
        console.log(userAccountRes, "top menu response");
      }
    };
    getRoleTyeListiting()
    getData();
  }, []);

  // useEffect(() => { 
  //   checkToEnableTheButton();
  //   return () => {
  //     setisDisabled(true);
  //   };
  // }, [firstName, lastName, roleTypeSetted]);

  // const checkToEnableTheButton = () => {
  //   // It will check for weather the credentials is filled or not
  //   console.log("checking");
  //   if ((firstName === "" || isNaN(firstName)) || (lastName === "" || isNaN(lastName))) {
  //     setisDisabled(true);
  //   } else {
  //     setisDisabled(false);
  //   }
  // };

 
      const getRoleTyeListiting = async (event) => {
        const receivedData = await getRoleTyeListService(navigate );
    
        console.log(receivedData, "goalValue");
  
        if (receivedData !== "invalid") {
            // const firstItem = receivedData[0];
            // console.log(firstItem, "firstItem")
            setRoleType(receivedData);
            console.log(receivedData, "receivedData")
            
        //   updateFetchingStatus(fetchingStatus.success);
        } else {
        //   updateFetchingStatus(fetchingStatus.failure);
        }
      };
      

  const handleOrganizationAddUpdateEmployee = async () => {


    const bodyContent = { 
      employeeId: employeeId,
        roleId: roleTypeSetted,  
        employeeFirstName: firstName, 
        employeeLastName: lastName, 
      }; 
      console.log(bodyContent, "bodyContent")

    const postResponse = await postAddUpdateemployeeService(navigate, bodyContent);
    console.log(postResponse, "postResponse")

    if (postResponse !== "invalid" && postResponse) {
    //   navigate(next()); 
    setfirstName("")
    setlastName("") 
    const userAccountRes = await getAccountUserDetails(navigate);
      console.log(userAccountRes, "userAccountRes")

     
      if (userAccountRes !== "invalid" && userAccountRes) {
        // setEmployeeId(userAccountRes.employeeId);  
        console.log(userAccountRes, "top menu response");
        if(userAccountRes.status == 0){
          console.log("navigate yo dash")
          navigate("/dashboard");
         } else if (userAccountRes.status == 1) {
          console.log("navigate yo role-type")
          navigate("/select-role-type"); 
          
         } else if (userAccountRes.status == 2) {
          console.log("navigate yo create-org")
          console.log(userAccountRes,"navigate yo role-type")
          navigate("/create-organization"); 
         
         } else if (userAccountRes.status == 4) {
          console.log("navigate yo reg")
          navigate("/organization-registration");
         }
        }
    // navigate("/create-organization")
    
    } else {
      message.error("Something went wrong");
    }
  };
  



    const handleChangeRoleType = (event) => {
        settedRoleType(event.target.value);
        // console.log(event, "(event.target.value)")
      };

    return (
        <div className="create_organization_container">
            <div className="logo_container">
                <img src={logo} alt="logo" />
            </div>
           <div className="d-flex justify-content-center w-100">
           <div className="center_organization_container">
                <h2 className="oragnization_title">Select your role type</h2>
                <p className="oragnization_sub">Enter your name and select your role</p>
                <div className="row">
                 
                <div className="col-md-6">
        <label className="modal_label">
        First name <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input bg-white"
          value={firstName}
          onChange={(e) => setfirstName(e.target.value)}
         
        />
      </div>
      <div className="col-md-6">
        <label className="modal_label">
        Last name <span className="text-danger">*</span>
        </label>
        <Input
          className="border-input bg-white"
          value={lastName}
          onChange={(e) => setlastName(e.target.value)}
         
        />
      </div>

      <div className="col-md-12">
                  <label className="modal_label">Role type</label><span className="text-danger">*</span>
                  <br />
                  <Select
                    // value={medicalConditionList}
                    onChange={handleChangeRoleType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {roleType?.map(item => <MenuItem key = {item.roleId} value={item.roleId}>{item.roleName}</MenuItem>)}
                   
                  </Select>
                  
                </div>

      
        
      <button className="organization_btn ms-3 mt-3" type="button" disabled={isDisabled}  style={{width: "200px"}} onClick={handleOrganizationAddUpdateEmployee}>Continue</button>
                </div>
            </div>
           </div>
        </div>
    )
}

export default SelectRoleType;